<template>
    <div class="d-flex align-item-end flex-column flex-wrap">
        <input-select-validation :ref="mostrarOcultarSelect" id="selectss" @change="addToList" class="col-12" :label="labelSelect" v-model="dataSelected" :datas="props.getData"></input-select-validation>
        <p class="text-muted mt-3 col-12 d-flex ps-1 mb-0 ps-2"> {{props.title ?? 'Lista:'}} </p>
        <div class="col-12 d-flex flex-row flex-wrap mt-3">
            <div v-for="(returnData, index) in props.returnDatas" :key="index" class="mx-1">
                <btn-loading class="btn btn-outline-danger border border-0" appendIcon="fa fa-times ms-2" 
                :ref="createLoaderVar(index)"
                :texto="getKeys(returnData, arrayKey)" 
                :sending="loader[index]" 
                :disabled="loader[index]" 
                @click="removeToList(returnData, index)">
                </btn-loading>
            </div>
        </div>
    </div>
</template>
<script setup>
import InputSelectValidation from '@/components/admin/template/InputSelectValidation'
import BtnLoading from '@/components/admin/template/BtnLoading'
import { defineProps, defineEmits, ref, watchEffect } from 'vue'
import BasicRequest from '@/utils/basic-request'
import Alert from '@/utils/alerts'
/*porps*/
const props = defineProps({
    title:{ // titulo que apaece arriba de la lista de botones
        type: String,
        default: null
    },
    routeDelete: String, //ruta donde borra el dato del backend (OPCIONAL)
    labelSelect: String, // es el label que aparece arriba y se pasa al componente input-select-validation
    arrayKey: String, // es el texto que va a mostrar en el btn-loading
    getData: {}, // es la lista de datos que va a mostrar el select
    returnDatas: {} // mantiene actualizado el array que se le pasa, cumpliria la funcion del v-model (agrega y borra datos del array pasado)
})
/*vars*/
const dataSelected = ref();
const list = ref([]);
const loader = ref([]);
const elselect = ref();

/* emits */
const emit = defineEmits(['update:returnDatas', 'delete'])

/* functions */
const addToList = () => {
    let search
    if(props.getData)
    search = props.getData.find((elemento)=> elemento.id == dataSelected.value)
    if(!search)
        return false
    list.value.push(search)
}
const removeToList = async (data, index)=>{
    try{
        loader.value[index] = true
        if(data.id && props.routeDelete)
        await BasicRequest.deleteData(props.routeDelete+'/'+data.id)
        list.value.splice(index, 1)
        loader.value[index] = false
        dataSelected.value = undefined
    }catch(error){
        Alert.Error({text: 'Error al eliminar'})
    }
}
const createLoaderVar = (index) => {
    loader.value[index] = loader.value[index] ? loader.value[index] : false
}
const getKeys = (data, key)=>{
    //busca la key de un array en el caso que sea un objeto
    let keys = key.split('.')
    let retorno = data;
    keys.forEach((e) => {
        for(var propiedad in retorno){
            if(e == propiedad){
                retorno = retorno[propiedad];
                return true;
            }
        }
    })
    return retorno
}

const mostrarOcultarSelect = () => {
    elselect.value = document.querySelector("#selectss");
    if(elselect.value){
        [...elselect.value.options].map((option)=>{
            if(list.value && list.value.find((selected)=> selected.id == option.value))
                option.style.display = 'none'
            else
                option.style.display = ''
        })
    }
}

/* watch */
watchEffect(()=>{
    dataSelected.value
    list.value = props.returnDatas
    // if(list.value)
    // mostrarOcultarSelect()
    emit('update:returnDatas', list.value)
})

// const perrito = (event) => {
//     console.log(event.currentTarget)
//     mostrarOcultarSelect()
// }
</script>