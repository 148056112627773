let controladorTiempo = 0
export class Delay {
    static awaitFunction(time){
        return new Promise((resolve)=>{
            clearTimeout(controladorTiempo);
            controladorTiempo = setTimeout(()=>{
                 return resolve(true)
            },time);
         });
     }
}