<template>
    <form @submit.prevent="updateSubCategoria" class="mb-4">
        <template-container title="Editar subcategoría">
            <template v-slot:contenido>
                <div class="d-flex align-item-end flex-row flex-wrap">
                    <inputs-validation class="col-12 col-sm-12" label="Descripción" v-model="subcategoria.descripcion" :isInvalid="$v.subcategoria.descripcion.$invalid && send ? true : false"></inputs-validation>
                </div>
                <data-list-buttons class="my-3" title="Categorías pertenecientes:" label-select="Categorías" array-key="descripcion" :return-datas="subcategoria.categorias" :get-data="categorias"></data-list-buttons>
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-end">
                    <btn-loading class="btn-danger me-3" icon="fa fa-trash" texto="eliminar" :sending="loadingEliminar" :disabled="loadingEliminar" @click="eliminarSubCategoria"></btn-loading>
                    <btn-loading type="submit" class="btn-primary" icon="fa fa-pencil" :sending="loading" :disabled="loading"></btn-loading>
                </div>
            </template>
        </template-container>
    </form>
</template>
<script setup>
import TemplateContainer from '@/components/admin/template/TemplateContainer'
import InputsValidation from '@/components/admin/template/InputsValidation'
import BtnLoading from '@/components/admin/template/BtnLoading'
import DataListButtons from '@/components/admin/template/DataListButtons'
import BasicRequest from '@/utils/basic-request'
import Alert from '@/utils/alerts'
import {$v as validations} from '@/components/admin/subcategoria/validations'
import { useRouter, useRoute } from 'vue-router'
import { ref } from 'vue'
/* vars */
const subcategoria = ref({});
const categorias = ref({});
const send = ref(false);
const loading = ref(false);
const loadingEliminar = ref(false);
const router = useRouter();
const route = useRoute();

/*validations*/
const $v = validations({subcategoria});

/* functions */
const getSubCategoria = async () =>{
    await BasicRequest.getData('subcategorias/'+route.params.id)
    .then( e => subcategoria.value = e.subcategoria )
    .catch(()=>{
        Alert.Error({text: 'No se pudo obtener la subcategoría'}).then(()=> router.push( {name: 'ListarSubCategorias'} ) )
    })
}
getSubCategoria();
const updateSubCategoria = async ()=>{
    send.value = true
    if($v.value.subcategoria.$invalid)
        return false
    loading.value = true;
    try{
        await BasicRequest.putData('subcategorias/'+subcategoria.value.id, subcategoria.value)
        Alert.Success({text: 'Subcategoría modificado con éxito'})
        .then(()=> router.push( {name: 'ListarSubCategorias'} ) )
    }catch(error){
        Alert.Error({text: 'Error al modificar la subcategoría'})
    }
    finally{
        loading.value = false
    }
}
const eliminarSubCategoria = ()=>{
    Alert.Option({ icon: 'warning', html: '<span>Estas a punto de eliminar esta subcategoría.</span></br><span>¿Deseas continuar?</span>'})
    .then((response)=>{
        if(response.isConfirmed){
            loadingEliminar.value = true;
            BasicRequest.deleteData('subcategorias/'+subcategoria.value.id)
            .then(()=>{
                Alert.Success({text: 'Subcategoría eliminada con éxito'}).then(()=> router.push( {name: 'ListarSubCategorias'} ) )
            })
            .catch(()=> Alert.Error({text: 'Error al eliminar subcategoría'}) )
            .finally(()=> loadingEliminar.value = false )
        }
    })
}

const getCategorias = async () =>{
    await BasicRequest.getData('categorias')
    .then( e => categorias.value = e.categorias )
}
getCategorias()
</script>