<template>
    <div class="h-100">
        <template v-if="notas && notas.length > 0">
            <nota-principal :data="notas[0].notas[0]"></nota-principal>
            <mini-notas class="col-sm-10 col-12 pb-5" :categorias="notas"></mini-notas>
        </template>
        <template v-else>
            <div class="d-flex justify-content-center aling-items-center py-5">
                <p class="text-muted">No se encontraron noticias...</p>
            </div>
        </template>
    </div>
</template>
<script setup>
import NotaPrincipal from '@/components/public/template/NotaPrincipal'
import MiniNotas from '@/components/public/template/MiniNotas'
import BasicRequest from '@/utils/basic-request'
import Alert from '@/utils/alerts'
import { ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useHead } from '@vueuse/head'
/* vars */
const notas = ref({});
const route = useRoute();

/* functions */
const getNotas = async () =>{
    try{
        if(route.params.id)
        await BasicRequest.getData('get-notas-categoria/'+route.params.id)
        .then( e => notas.value = e.notas )

        if(route.params.subcategoria)
        await BasicRequest.getData('get-notas-subcategoria/'+route.params.subcategoria)
        .then( e => notas.value = e.notas )
        let metaTagImagenRuta = '../../assets/logo.png';
        // let metaTagImagenRuta = require('@/assets/logo.png');
        useHead({
            title: 'Noticias | María Auxiliadora',
            meta: [
                {
                name: `description`,
                content: `Descubre las últimas noticias y eventos del Instituto Privado María Auxiliadora, 
                una institución educativa comprometida con la excelencia académica y la formación integral de sus estudiantes. 
                Desde educación inicial hasta secundaria, nuestro equipo de educadores altamente capacitados trabaja para brindar una educación de calidad en un ambiente seguro y acogedor.`,
                },
                {
                name: `keywords`,
                content: 'Instituto María Auxiliadora, educación, formación integral, excelencia académica, educación inicial, educación secundaria, educación privada, educación de calidad, eventos educativos, noticias educativas.',
                },
                {
                name: `og:title`,
                content: 'Noticias María Auxiliadora',
                },
                {
                name: `og:image`,
                content: metaTagImagenRuta,
                },
                {
                name: `og:description`,
                content: `Descubre las últimas noticias y eventos del Instituto Privado María Auxiliadora, 
                una institución educativa comprometida con la excelencia académica y la formación integral de sus estudiantes. 
                Desde educación inicial hasta secundaria, nuestro equipo de educadores altamente capacitados trabaja para brindar una educación de calidad en un ambiente seguro y acogedor.`,
                },
            ],
                
        })
    }catch(error){
        Alert.Error({text: 'No se pudieron obtener las noticias'})
    }
}
getNotas()

/* watchs */
watch(
  () => route.params.id,
  (newValue) => {
    if(newValue)
        getNotas();
  },
  { deep: true }
)
watch(
  () => route.params.subcategoria,
  (newValue) => {
    if(newValue)
        getNotas();
  },
  { deep: true }
)

</script>
