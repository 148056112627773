<template>
    <footer class="footer-distributed">
        <div class="footer-left">
            <h2 class="text-violeta text-center mb-4 mb-sm-0">Maria Auxiliadora</h2>
            <div class="logo-footer">
                <img src="@/assets/publico/Escudo.png" alt="logo">
            </div>
        </div>
        <div class="footer-right">            
            <div class="d-flex align-items-center justify-content-center justify-content-sm-start mt-2">
                <h3 class="text-violeta mb-3 text-center">Contactos</h3>
            </div>
            <div class="footer-right-container ps-2">            
                <div class="d-flex align-items-center mb-2">
                    <div class="bolita rounded-circle background-violeta"> 
                        <i class="fa fa-map-marker text-white"></i>
                    </div>
                    <p class="m-0 ms-3 fw-bolder text-muted">Santa Rosa, La Pampa</p>
                </div> 
                <div class="d-flex align-items-center">
                    <div class="rounded-circle bolita background-violeta">
                        <i class="fa fa-phone text-white"></i>
                    </div>
                    <p class="m-0 ms-3 fw-bolder text-muted">+54 2954-774712 / 830706</p>
                </div>
                <h6 class="mt-3">Niveles</h6>
                <div></div>
                <div class="d-flex align-items-center mt-2">
                    <div class="rounded-circle bolita background-violeta me-2">
                        <i class="fa fa-envelope text-white"></i>
                    </div>
                    <a class="text-secondary fw-bolder text-decoration-none" href="mailto:auxiliadora.inicial.santarosa@lapampa.edu.ar">
                        auxiliadora.inicial.santarosa@lapampa.edu.ar
                    </a>
                </div>
                <div class="d-flex align-items-center mt-2">
                    <div class="rounded-circle bolita background-violeta me-2">
                        <i class="fa fa-envelope text-white"></i>
                    </div>
                    <div class="text-break">
                        <a class="text-decoration-none text-muted fw-bolder" href="mailto:auxiliadora.primario.santarosa@lapampa.edu.ar">
                            auxiliadora.primario.santarosa@lapampa.edu.ar
                        </a>
                    </div>
                </div>
                <div class="d-flex align-items-center mt-2">
                    <div class="rounded-circle bolita background-violeta me-2">
                        <i class="fa fa-envelope text-white"></i>
                    </div>
                    <div class="text-break">
                        <a class="text-decoration-none text-muted fw-bolder" href="mailto:auxiliadora.secundario.santarosa@lapampa.edu.ar">
                            auxiliadora.secundario.santarosa@lapampa.edu.ar
                        </a>
                    </div>
                </div>
                <div class="d-flex mt-2 flex-column mt-3">

                </div>
                <div class="d-flex mt-2 flex-column mt-3">
                    <div class="fw-bolder">
                        <h6>Administración</h6>
                    </div>
                    <div class="d-flex">
                        <div class="rounded-circle bolita background-violeta me-2">
                            <i class="fa fa-envelope text-white"></i>
                        </div>
                        <a class="text-muted text-decoration-none fw-bolder" href="mailto:imastarosa@gmail.com">
                            imastarosa@gmail.com
                        </a>
                    </div>
                </div>
                <div class="d-flex mt-2 flex-column mt-3">
                    <div class="fw-bolder">
                        <h6>Institucional</h6>
                    </div>
                    <div class="d-flex">
                        <div class="rounded-circle bolita background-violeta me-2">
                            <i class="fa fa-envelope text-white"></i>
                        </div>
                        <a class="text-muted text-decoration-none fw-bolder" href="mailto:imastarosa@gmail.com">
                            mauxiliadorarl@gmail.com
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div class="redes-sociales">
            <p class="text-white mb-0 col-sm-6 col-lg-auto me-lg-3">Visita nuestras redes sociales</p>
            <div class="footer-icons col-sm-3">
                <a href="https://www.facebook.com/IMASantaRosa" title="Ver en facebook"><i class="fa-brands fa-facebook-f"></i></a>
                <a href="https://www.instagram.com/institutomariaauxiliadora/" title="Ver en instagram"><i class="fa-brands fa-instagram"></i></a>
            </div>
        </div>
    </footer> 
</template>
<style scoped src="@/assets/publico/footer.css"></style>
