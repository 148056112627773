<template>
     <template-container title="Mis noticias" class="mb-5">
        <template v-slot:top>
            <div class="col-12 d-flex justify-content-end">
                <router-link :to="{ name: 'CreateNota' }" class="btn btn-primary"><i class="fa fa-file-lines"></i> Nueva Noticia </router-link>
            </div>
        </template>
        <template v-slot:contenido>
            <div>
                <input-search id="search" class="col-12 col-sm-6" v-model="search" :cargando="loading"></input-search>   
                <data-list :fields="fields" :datas="notas.data" @evento="router.push({ name: 'EditNota', params: {id: $event.slug} })"></data-list>
                <p class="text-center" v-if="notas.data && notas.data.length <= 0">No hay datos...</p>
                <div class="d-flex justify-content-center" v-if="notas.data && notas.data.length > 0">
                    <template-paginate :pages="notas" @changePage="getNotas($event)"></template-paginate>
                </div>
            </div>
        </template>
    </template-container>
</template>
<script setup>
import TemplateContainer from '@/components/admin/template/TemplateContainer'
import DataList from '@/components/admin/template/DataList'
import InputSearch from '@/components/admin/template/InputSearch'
import BasicRequest from '@/utils/basic-request'
import { ref, watchEffect, watch } from 'vue'
import TemplatePaginate from '@/components/admin/template/TemplatePaginate'
import Fecha from '@/utils/fecha'
import Alert from '@/utils/alerts'
import { Delay } from '@/utils/delay'
import { useRouter } from 'vue-router'

/*vars*/
const notas = ref({});
const loading = ref(false)
const search = ref();
const router = useRouter();
const fields = [
    {
        titulo: 'Titulo',
        key: 'titulo',
        classTitulo: 'text-primary',
        classKey: 'fw-bold'
    },
    {
        titulo: 'Pie:',
        key: 'pie_foto',
        classTitulo: 'text-muted',
        classKey: 'text-muted fw-bold fw-sm-normal',
    },
    {
        titulo: 'Categoria:',
        key: 'categorias.descripcion',
        classTitulo: 'text-muted',
        classKey: 'text-muted fw-bold fw-sm-normal d-flex flex-column col-12',
    },
    {
        titulo: 'Fecha:',
        key: 'created_at',
        classTitulo: 'text-muted',
        classKey: 'text-muted fw-bold fw-sm-normal',
    }
]

/* functions */
const getNotas = (page, searchParam) =>{
    BasicRequest.getData('notas?page='+page, {params:{paginate:5, searchParam: searchParam}})
    .then( e => notas.value = e.notas )
    .catch(()=> Alert.Error({text: 'No se pudieron obtener las noticias'}) )
}
getNotas('0', '')
const busquedaConDelay = async ()=>{
    loading.value = true;
    await Delay.awaitFunction(2000)
    .then(() => {
        loading.value = false
        getNotas('0', search.value) 
    })
}

/* watchers */
watchEffect(()=>{
    if(notas.value.data)
        notas.value.data.filter((e)=> e.created_at = Fecha.formatDate(e.created_at, "DD/MM/YYYY"))
})
watch(search, ()=> busquedaConDelay() )
</script>
