<template>
<div>
    <div v-for="(categoria, index) in categorias" :key="index">
        <div class="col-auto border-subttitle py-2 position-relative mb-3 mt-4 mini-notas-subtitles" v-if="categorias.length > 0 && categoria.notas.length > 0">
            <div class="elipsis-2 pin mb-3 mt-4">
                <router-link class="text-decoration-none elipsis pe-3" :to="{ name: 'ListarNotasPorCategoria', params:{ id: categoria.id }}">
                    <span class="py-2 text-violeta position-relative">
                        {{categoria ? categoria.descripcion : ''}} 
                        <i class="fa fa-angle-right"></i>
                    </span>
                </router-link>
                <router-link class="text-decoration-none px-3" :to="{ name: 'ListarNotasPorSubcategoria', params:{ subcategoria: subcateg.id }}" v-for="subcateg in categoria.subcategoria" :key="subcateg.id"><small class="text-muted" style="font-size:13px; text-transform: uppercase;"> {{ subcateg.descripcion +' '}} </small></router-link>
            </div>
            <div class="col-12 d-flex justify-content-center align-items-center mb-4 listar-subcategorias">
                <a class="p-0 m-0 col-8 text-muted border-dark text-center" @click="verMas" style="font-size:15px">Ver mas...</a>
            </div>
        </div>
        <div class="container-mini-notas">
            <router-link :to="{ name: 'VerNota', params:{ id: nota.slug }}" class="text-decoration-none text-dark mini-notas bg-white border rounded background-negro" v-for="(nota, index) in categoria.notas" :key="index">
                <div class="col-12 imagen-nota" v-if="nota.imagenes && nota.imagenes.length > 0">
                    <img :src="backend+'/'+nota.imagenes[0].ruta" @error="imgError" :alt="nota.titulo" class="">
                    <!-- <img :src="backend+'/'+nota.imagenes[0].ruta" @error="imgError" alt="" class=""> -->
                    <div class="radial-gradiente"></div>
                    <div class="gradiente"></div>
                </div>
                <div class="col-12 imagen-nota" v-else>
                    <img src="@/assets/no-image-1.png" alt="No imagen available" class="">
                    <img src="@/assets/no-image-1.png" alt="No imagen available" class="">
                </div>
                <div class="col-12 contenedor-subcategorias mt-sm-2 px-1">
                    <template v-for="categ in nota.categorias" :key="categ.id">
                        <div class="subcategorias" v-for="subcategoria in categ.subcategorias" :key="subcategoria.id">
                            <small>
                                {{subcategoria.descripcion}}
                            </small>
                        </div>
                    </template>
                </div>
                <div class="col-12 pt-1 px-1">
                    <span class="elipsis-2 titulo">
                        <b>{{nota.titulo}}</b>
                    </span>
                </div>
                <div class="col-12 px-1">
                    <span class="text-muted elipsis-2">
                        {{nota.pie_foto}}
                    </span>
                    <!-- <router-link class="text-decoration-none" :to="{ name: 'VerNota', params:{ id: nota.slug }}"><span class="text-muted text-decoration-underline"><b>Leer mas...</b></span></router-link> -->
                </div>
            </router-link>
        </div>
    </div>
</div>
</template>
<style scoped src="@/assets/publico/mininotas.css"></style>
<script setup>
import { defineProps, onMounted } from 'vue'

/* props */
defineProps({
    categorias: {
        type: Object,
        default: function(){
            return [];
        }
    }
})
/* vars */
const backend = process.env.VUE_APP_URL_IMAGES;

/* functions */
const imgError = (event) =>  event.target.src = require('@/assets/no-image-1.png')

const agregarVerMas = ()=>{
    if(document.querySelectorAll(".pin").length == 0)
        return false
    let contenedorCategorias = document.querySelectorAll(".pin");
    
    [...contenedorCategorias].map((categorias)=>{
        if(categorias.offsetHeight != categorias.scrollHeight && categorias.offsetHeight+6 != categorias.scrollHeight){
            categorias.nextElementSibling.classList.remove('d-none')
            return true
        }
        categorias.nextElementSibling.classList.add('d-none')
        return false
    })
}

const verMas = ()=>{
    if(event.target.innerText == 'Ver mas...')
        event.target.innerText = 'Ver menos...'
    else
        event.target.innerText = 'Ver mas...'
    event.target.parentElement.previousElementSibling.classList.toggle('elipsis-2')
}
onMounted(()=> agregarVerMas() )
</script>
