import { createApp } from 'vue'
import App from './App.vue'
import router from '@/routes'
import Axios from 'axios'
import { createPinia } from 'pinia'
import { UseAuthStore } from '@/store/authStore'
import { createHead } from '@vueuse/head'
const head = createHead()

Axios.defaults.baseURL = process.env.VUE_APP_URL;

document.querySelector("#cargando-app").remove()

const app = createApp(App)
app.use(createPinia())
app.use(head)

const useAuthStore = UseAuthStore();
useAuthStore.token = localStorage.getItem('token');
// useAuthStore.getUserProfile();

useAuthStore.$subscribe((mutation, state) => {
    if(mutation.storeId == 'auth'){
        Axios.defaults.headers.common['Authorization'] = 'Bearer '+state.token;
        localStorage.setItem('token', state.token)
    }
})

app.use(router)
app.mount('#app')
