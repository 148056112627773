import { createWebHistory, createRouter } from "vue-router";
import LoginView from '@/components/LoginView'
import ResetPassword from '@/components/ResetPassword'
import ChangePassword from '@/components/ChangePassword'
import { AdminRoutes } from './admin'
import { PublicRoutes } from './public'
import { UseAuthStore } from '@/store/authStore'

import PrevisualizarNota from '@/components/admin/nota/PrevisualizarNota'

const login = [
    {
        path: "/login",
        name:"LoginView",
        component: LoginView,
        meta: {
            private:false,
            cliente: true
        }
    }
    
]
const resetPassword = [
    {
        path: "/reset-password",
        name:"ResetPassword",
        component: ResetPassword,
        meta: {
            private:false,
            cliente: true
        }
    }
    
]
const previsualizacion = [
    {
        path: "/previsualizar/noticia",
        name:"PrevisualizarNota",
        component: PrevisualizarNota,
        meta: {
            private:true,
            admin: true
        }
    },
]
const changePassword = [
    {
        path: "/cambiar-password",
        name:"ChangePassword",
        component: ChangePassword,
        meta: {
            private:false,
            cliente: true
        }
    }
    
]
const routes = [
    ...login,
    ...resetPassword,
    ...changePassword,
    ...AdminRoutes,
    ...PublicRoutes,
    ...previsualizacion
]

const router = createRouter({
    history: createWebHistory(),
    routes,
    scrollBehavior () {
        return { top: 0 }
    }
})
router.beforeEach(async (to, from, next) => {
    if(to.meta.admin){
        const useAuthStore = UseAuthStore();
        await useAuthStore.getUserProfile().then(()=> true).catch(()=> false)
        if(!useAuthStore.isLoggin){
            return next({ name: 'LoginView' })
        }
    }
    return next();
})

export default router