<template>
    <div class="bg-dark shadow">
        <a href="/" class="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"> 
            <svg class="bi me-2" width="40" height="32"> </svg> 
            <span class="fs-4">Mi Menú</span> 
        </a>
        <hr>
        <ul class="nav nav-pills flex-column mb-auto">
            <li>
                <router-link class="nav-link text-white" exact aria-current="page" :to="{name: 'PrivateIndex'}">
                    <i class="fa fa-home"></i><span class="ms-2">Home</span> 
                </router-link>
            </li>
            <template v-for="(ruta, i) in rutas.children">
                <li v-if="ruta.btn" :key="i">
                    <router-link @click="change" class="nav-link text-white" active-class="active" exact aria-current="page" :to="{name: ruta.name}">
                        <i :class="ruta.btn.icon"></i><span class="ms-2">{{ruta.btn.text}}</span> 
                    </router-link>
                </li>
            </template>
        </ul>
        <div class="dropdown mt-2 px-2 pt-3 border-top border-secondary"> 
            <a href="#" class="d-flex align-items-center text-white text-decoration-none dropdown-toggle" id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false"> 
                <!-- <img src="https://github.com/mdo.png" alt="" width="32" height="32" class="rounded-circle me-2">  -->
                <i class="fa fa-user px-2"></i>
                <strong>{{usuario.apellido}}</strong> 
            </a>
            <ul class="dropdown-menu dropdown-menu-dark text-small shadow" aria-labelledby="dropdownUser1">
                <li>
                    <router-link @click="change" class="dropdown-item btnMenu nav-link" :to="{name: 'VerPerfil'}">Mi Perfil</router-link>
                </li>
                <li>
                    <hr class="dropdown-divider">
                </li>
                <li><a class="dropdown-item" @click="logout">Salir</a></li>
            </ul>
        </div>
    </div>
</template>
<style scoped>
    .nav-link:hover{
        background-color:#525252 !important;
    }
    .nav-link .fa{
        transition:all 1s;
    }

    .nav-link:hover .fa{
        transform:rotate(360deg);
    }
    .nav-link svg{
        transition:all 1s;
    }

    .nav-link:hover svg{
        transform:rotate(360deg);
    }
</style>
<script setup>
import { AdminRoutes } from '@/routes/admin'
import { ref } from 'vue'
import { UseAuthStore } from '@/store/authStore'
import { useRouter } from 'vue-router'
const rutas = ref(...AdminRoutes)
const width = ref(window.innerWidth)
const router = useRouter();

const usuario = UseAuthStore().usuario;
const logout = ()=>{
    UseAuthStore().logout().then(()=>{
        router.push({ name: 'PublicIndex' })
    })
}
const change = () => {
    if(width.value < 601){
        let btn = document.querySelector(".lines");
        btn.classList.toggle("animate");
        let menu = document.querySelector(".contenedor")
        menu.querySelector(".template-sidebar").classList.toggle("esconder-menu")
        menu.querySelector(".template-container").classList.toggle("esconder-menu")
        menu.classList.toggle("admin")
        menu.classList.toggle("admin-menu-hide")
    }
}
</script>
