<template>
    <div class="vh-100 col-12 d-flex align-items-center justify-content-center px-3">
        <pre-loader v-show="loading"></pre-loader>
        <form @submit.prevent="login" class="col-sm-5 col-12">
            <h2 class="text-muted pb-2"> Ingrese sus credenciales</h2>
            <div class="mb-2">
                <label for="" class="text-muted fw-bold"><i class="fa-solid fa-envelope"></i> Email</label>
                <input type="email" v-model="user.email" class="form-control" placeholder="Ingrese su email" :class="v.email.$invalid && send ? 'is-invalid' : ''">
                <div class="invalid-feedback">
                    <i class="fa fa-warning pe-2"></i>Debe ingresar un email válido
                </div>
            </div>
            <div>
                <label for="" class="text-muted fw-bold"><i class="fa-solid fa-key"></i> Password</label>
                <input type="password" v-model="user.password" class="form-control" placeholder="Ingrese su passoword" :class="v.password.$invalid && send ? 'is-invalid' : ''">
                <div class="invalid-feedback">
                    <i class="fa fa-warning pe-2"></i>Debe ingresar su password
                </div>
            </div>
            <div class="col-12 justify-content-end d-flex flex-column-reverse flex-sm-row mt-2">
                <router-link class="text-violeta btn" :to="{ name:'ResetPassword' }">Olvide mi contraseña</router-link>
                <button type="submit" class="boton boton-violeta col-sm-3 col-12"><i class="fa-solid fa-power-off me-3"></i> Ingresar</button>
            </div>
        </form>
    </div>
</template>
<style scoped src="@/assets/publico/style.css"></style>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { UseAuthStore } from '@/store/authStore'
import Alert from '@/utils/alerts'
import PreLoader from '@/components/public/template/PreLoader'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

/* vars */
const useAuthStore = UseAuthStore();
useAuthStore.$reset();
const router = useRouter();
const loading = ref(false);
const user = ref({});
const send = ref(false);
const rules = {
    email: { required, email },
    password: { required },
}
const v = useVuelidate(rules, user);

/* functions */
const login = async ()=>{
    send.value = true
    if(v.value.$invalid){
        return false
    }
    loading.value = true
    try{
        await useAuthStore.login(user.value)
        useAuthStore.getUserProfile();
        router.push( {name: 'PrivateIndex'} )
    }catch(e){
        loading.value = false
        Alert.Error({text: 'Error al intentar ingresar. Verifique su usuario y contraseña.'})   
    }
}

// onMounted(()=>{
//     document.addEventListener('keyup', (event)=>{
//         if(event.key == 'Enter')
//             login();
//     })
// })
</script>