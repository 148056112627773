<template>
    <div class="mb-5 pb-5">
        <div class="titlo-nota mt-3 mb-5">
            <h2 class="fw-bolder text-violeta">Galeria</h2>
        </div>
        <div v-if="galerias && galerias.data && galerias.data.length == 0">
            <p class="text-muted">Aun no se han cargado galerias de fotos</p>
        </div>
        <div class="container-galeria">
            <div class="hoverable" v-for="(galeria, i) in galerias.data" :key="i" @click="viewGaleria(galeria)">
                <div class="galeria-images" v-if="galeria.imagenes && galeria.imagenes.length > 0">
                    <img class="imagen" :src="backend+'/'+galeria.imagenes[0].ruta" @error="imgError" alt="">
                    <img class="imagen-fondo" :src="backend+'/'+galeria.imagenes[0].ruta" @error="imgError" alt="">
                </div>
                <div class="galeria-images" v-else>
                    <img class="imagen" :src="require('@/assets/no-image-1.png')" @error="imgError" alt="">
                    <img class="imagen-fondo" :src="require('@/assets/no-image-1.png')" @error="imgError" alt="">
                </div>
                <div class="galeria-data">
                    <b>{{galeria.titulo}}</b>
                </div>
            </div>
            <ver-galeria v-if="ver" :descripcion="ver.descripcion" :titulo="ver.titulo" :imagenes="ver.imagenes" @cerrar="()=> ver=null"></ver-galeria>
        </div>
    </div>
</template>
<style scoped src="@/assets/publico/galeria.css"></style>
<script setup>
import BasicRequest from '@/utils/basic-request'
import { ref } from 'vue'
import Alert from '@/utils/alerts'
import VerGaleria from '@/components/public/galeria/VerGaleria'
/* vars */
const galerias = ref({});
const ver = ref([]);
const backend = process.env.VUE_APP_URL_IMAGES;

/* functions */
const getGalerias = (page, searchParam) =>{
    BasicRequest.getData('get-galeria?page='+page, {params:{paginate:5, searchParam: searchParam}})
    .then( e => galerias.value = e.galerias )
    .catch(()=> Alert.Error({text: 'No se pudieron obtener las galerias'}) )
}
getGalerias('0', '')

const imgError = (event)=>{
    event.target.src = require('@/assets/no-image-1.png')
}

const viewGaleria = (data) => {
    ver.value = data
}
</script>