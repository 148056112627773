<template>
    <div>
        <template-container title="Modificar galeria" class="mb-5">
            <template v-slot:contenido>
                <div>
                    <inputs-validation class="col-12 col-sm-12" label="Titulo" v-model="galeria.titulo" :isInvalid="$v.galeria.titulo.$invalid && send ? true : false"></inputs-validation>
                    <!-- <input-text-area-validation class="col-12 col-sm-12 my-3" placeholder="Ingrese la Descripción..." label="Descripción" v-model="galeria.descripcion" :isInvalid="$v.galeria.descripcion.$invalid && send ? true : false" errorMsg="Por favor ingrese el texto que estará abajo de la foto." ></input-text-area-validation> -->
                    <div class="col-12 my-3 d-flex flex-column">
                        <label for="" class="text-muted fw-bolder">Descripción</label>
                        <QuillEditor ref="editor" style="min-height: 150px" theme="snow" :toolbar="toolbar" contentType="html" v-model:content="galeria.descripcion" />
                        <!-- <div class="invalid-feedback" v-if="$v.galeria.descripcion.$invalid && send">
                            <i class="fa fa-warning pe-2"></i>Por favor ingrese el contenido de la galeria.
                        </div> -->
                    </div>
                </div>
            </template>
        </template-container>
        <template-container title="Imagenes">
            <template v-slot:contenido>
                <div class="d-flex align-item-end flex-row flex-wrap">
                    <load-images v-model="galeria.imagenes" @eliminar="deleteFile($event)"></load-images>
                </div>
            </template>
        </template-container>
        <template-container>
            <template v-slot:contenido>
                <div class="d-flex justify-content-end flex-column flex-sm-row gap-2">
                    <btn-loading class="btn-danger" texto="Eliminar" icon="fa fa-trash" :sending="loadingEliminar" :disabled="loadingEliminar" @click="eliminarGaleria"></btn-loading>
                    <button class="btn btn-secondary" @click="previsualizarGaleria"><i class="fa fa-eye"></i> Previsualizar</button>
                    <btn-loading class="btn-primary" icon="fa fa-plus" :sending="loading" :disabled="loading" @click="modificarGaleria"></btn-loading>
                </div>
            </template>
        </template-container>
        <ver-galeria v-if="ver" :descripcion="ver.descripcion" :titulo="ver.titulo" :imagenes="ver.imagenes" @cerrar="()=> ver=null"></ver-galeria>
    </div>
</template>
<script setup>
import TemplateContainer from '@/components/admin/template/TemplateContainer'
import InputsValidation from '@/components/admin/template/InputsValidation'
// import InputTextAreaValidation from '@/components/admin/template/InputTextAreaValidation'
import BtnLoading from '@/components/admin/template/BtnLoading'
import {$v as validations} from '@/components/admin/galeria/validations'
import LoadImages from '@/components/admin/template/LoadImages'
import { ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import BasicRequest from '@/utils/basic-request'
import Alert from '@/utils/alerts'
import { QuillEditor } from '@vueup/vue-quill'
import VerGaleria from '@/components/public/galeria/VerGaleria'
/* vars */
const galeria = ref({descripcion: null});
const editor = ref({});
const ver = ref([]);
const loading = ref(false);
const loadingEliminar = ref(false);
const send = ref(false);
const router = useRouter();
const route = useRoute();
const toolbar = [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ['clean'], // remove formatting button
]

/*validations*/
const $v = validations({galeria});


/* functions */
const modificarGaleria = ()=>{
    send.value = true
    if($v.value.galeria.$invalid)
        return false
    loading.value = true;
    BasicRequest.putData('galerias/'+galeria.value.id, galeria.value)
    .then(()=>{
        Alert.Success({text: 'Galeria modificada con éxito'}).then(()=> router.push( {name: 'ListarGaleria'} ) )
    })
    .catch(()=> Alert.Error({text: 'Error al crear galeria'}) )
    .finally(()=> loading.value = false )
}

const eliminarGaleria = ()=>{
    Alert.Option({ icon: 'warning', html: '<span>Estas a punto de eliminar esta Galeria.</span></br><span>¿Deseas continuar?</span>'})
    .then((response)=>{
        if(response.isConfirmed){
            loadingEliminar.value = true;
            BasicRequest.deleteData('galerias/'+galeria.value.id)
            .then(()=>{
                Alert.Success({text: 'Galeria eliminada con éxito'}).then(()=> router.push( {name: 'ListarGaleria'} ) )
            })
            .catch(()=> Alert.Error({text: 'Error al eliminar Galeria'}) )
            .finally(()=> loadingEliminar.value = false )
        }
    })
}
const getGaleria = async ()=>{
    await BasicRequest.getData('galerias/'+route.params.id)
    .then((response)=> galeria.value = response.galeria )
    editor.value.setHTML(galeria.value.descripcion)
}
const deleteFile = async (data)=>{
    await BasicRequest.deleteData('galeria-imagenes/'+data.id)
    .then(()=>{
        galeria.value.imagenes = galeria.value.imagenes.filter((e)=> e.id != data.id)
    })
    .catch( () => Alert.Error({text: 'Error al eliminar archivo'}) )
}
const previsualizarGaleria = () =>{
    Alert.Option({ 
        icon: 'info', 
        confirmButtonColor: "#198754",
        confirmButtonText: "Continuar",
        html: '<span>Se abrira una ventana para visualizar la galeria.</span></br><span>Tenga en cuenta que no se guardara hasta que presione el boton "guardar"</span>'
        })
    .then((response)=>{
        if(response.isConfirmed){
            ver.value = galeria.value         
        }
    })
}
getGaleria()

// watchEffect(()=>{
//     if(ver.value){
//         if(ver.value.imagenes)
//             ver.value.imagenes = ver.value.imagenes.filter((img)=> img.ruta = img.prev )
//     }
// })

</script>