<template>
    <div v-if="img.length > 0">
        <teleport to='body'>
            <div class="modalito">
                <div class="container-modalito">
                    <div class="header-modalito">
                        <!-- <h4 class="text-white">Lorem ipsum dolor sit amet consectetur adipisicing elit. Sed iure deleniti corporis. Culpa aut praesentium assumenda!?</h4> -->
                        <button class="btn btn-default" @click="cerrarGaleria"><i class="fa fa-times text-white"></i></button>
                    </div>
                    <div class="body-modalito">
                        <div class="photos">
                            <div class="controls">
                                <a :href="'#slider'+controls" @click="controlsDown"><i class="fa-solid fa-chevron-left"></i></a>
                            </div>
                            <ul class="galeria-slider">
                                <li :id="'slider'+i" class="galeria-slider-images" v-for="(img, i) in img" :key="i">
                                    <img :src="img.prev ?? backend+'/'+img.ruta" alt="">
                                    <p class="text-white">{{controls}}</p>
                                </li>
                            </ul>
                            <div class="controls">
                               <a :href="'#slider'+controls" @click="controlsUp"><i class="fa-solid fa-chevron-right"></i></a>
                            </div>
                        </div>
                        <div class="description">
                            <h4 class="text-white mb-4">{{props.titulo}}</h4>
                            <span v-html="props.descripcion" class="ql-editor p-0 me-2" :class="!mas ? 'elipsis-2' : ''" style="white-space: pre-wrap;">
                                
                            </span>
                            <span @click="verMas" class="text-muted text-decoration-underline" role="button"><b>{{!mas ? 'mas' : 'menos'}}</b></span>
                            <!-- <span>{{props.descripcion}}</span> -->
                        </div>
                    </div>
                </div>
            </div>
        </teleport>
    </div>
</template>
<style scoped src="@/assets/publico/vergaleria-modal.css"></style>
<script setup>
import { defineProps, ref, watchEffect, defineEmits } from 'vue'

const props = defineProps({
    imagenes:{
        type: Object,
        default: function(){
            return [];
        }
    },
    descripcion:{
        type: String,
        default: null
    },
    titulo:{
        type: String,
        default: null
    }
})

/* vars */
const backend = process.env.VUE_APP_URL_IMAGES;
const img = ref({...props.imagenes})
const controls = ref(0)
const totalImages = ref(0)
const mas = ref(false)
const emits = defineEmits(['cerrar'])
/* functions */

const cerrarGaleria = () => {
    img.value = [];
    emits('cerrar');
}

const controlsUp = () =>{
    controls.value ++
    if(controls.value > totalImages.value)
        controls.value = 0
}
const controlsDown = () =>{
    controls.value --
    if(controls.value < 0 )
        controls.value = totalImages.value
}
const verMas = () => {
    if(mas.value){

        mas.value = false;
        return false
    }
    if(!mas.value){

        mas.value = true;
        return true
    }
}

watchEffect(()=>{
    totalImages.value = props.imagenes.length - 1
    img.value = props.imagenes
})
</script>
