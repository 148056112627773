<template>
    <div>
        <router-link :to="{ name:'LoginView' }" class="boton boton-violeta mb-1 col-lg-11 col-12 m-auto me-sm-0 text-center d-flex d-sm-none"><i class="fa-solid fa-arrow-right-to-bracket pe-2"></i> Ingresar</router-link>
        <div class="menu d-flex align-items-end justify-content-end flex-column" v-for="(categoria, i) in categorias" :key="i">
            <router-link :to="{ name: 'ListarNotasPorCategoria', params:{ id: categoria.id }}" class="boton boton-violeta mb-1 col-lg-11 col-12 m-auto me-sm-0 text-center">
                {{categoria.descripcion}}
                <i v-show="categoria?.subcategoria.length > 0" class="ms-2 fa fa-arrow-down down"> </i>
                <i v-show="categoria?.subcategoria.length > 0" class="ms-2 fa fa-arrow-up up"> </i>
            </router-link>
            <ul class="submenu boton-violeta d-flex flex-row flex-sm-column flex-wrap col-sm-11 col-12">
                <li class="px-2 px-sm-0" v-for="(subcategoria, i2) in categoria.subcategoria" :key="i2">
                    <div class="col-12">
                        <router-link :to="{ name: 'ListarNotasPorSubcategoria', params:{ subcategoria: subcategoria.id }}" class="boton boton-violeta mb-1 col-sm-12 col-12 m-auto me-sm-0 text-center">{{subcategoria.descripcion}}</router-link>
                    </div>
                </li>
            </ul>
        </div>
        <router-link :to="{ name: 'ListarFormulariosFront' }" class="boton boton-violeta mb-1 col-lg-11 col-12 m-auto me-sm-0 text-center">Formularios</router-link>
        <router-link :to="{ name: 'ListarGaleriaFront' }" class="boton boton-violeta mb-1 col-lg-11 col-12 m-auto me-sm-0 text-center d-flex">Galeria</router-link>
        <router-link :to="{ name: 'QuienesSomos' }" class="boton boton-violeta mb-1 col-lg-11 col-12 m-auto me-sm-0 text-center mb-4 d-flex d-sm-none">¿Quienes somos?</router-link>
    </div>
</template>
<style scoped src="@/assets/publico/menu.css"></style>
<script setup>
import BasicRequest from '@/utils/basic-request'
import { ref } from 'vue'

/* vars */
const formularios = ref({});
const categorias = ref({});

/* functions */
const getFormularios = async () =>{
    await BasicRequest.getData('get-formularios')
    .then( e => formularios.value = e.formularios )
}
getFormularios()
const getCategorias = ()=>{
    BasicRequest.getData('get-categorias').then((e)=>{
        categorias.value = e.categorias
    })
}
getCategorias();
</script>
