<template>
     <div class="lines" @click="change($event)">
        <div class="menuline1"></div>
        <div class="menuline2"></div>
        <div class="menuline3"></div>
    </div>
</template>
<script setup>
const change = (event) => {
    let btn = event.target.closest(".lines");
    btn.classList.toggle("animate");
    let menu = document.querySelector(".contenedor")
    menu.querySelector(".template-sidebar").classList.toggle("esconder-menu")
    menu.querySelector(".template-container").classList.toggle("esconder-menu")
    menu.classList.toggle("admin")
    menu.classList.toggle("admin-menu-hide")
}
</script>
<style scoped src="@/assets/private/btnmenu.css"></style>