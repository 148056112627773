import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export const $v = data => useVuelidate(rules, data)

export const rules = {
    diaCalendario:{
        descripcion: { required },
        dia: { required },
        mes: { required },
        tipo: { required }
    }
}