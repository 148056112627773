import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export const $v = data => useVuelidate(rules, data)

export const rules = {
    formulario:{
        titulo: { required },
        links: { required }
    },
    link: {required}
}