<template>
    <div class="px-3 mt-5">
        <div class="my-3" v-if="$slots.top">
            <slot name="top">
                <div class="d-flex justify-content-between">
                    <div>
                        algo que podria ir arriba tipo titulo
                    </div>
                    <div>
                        <button class="btn btn-primary"><i class="fa fa-user"></i> Texto</button>
                    </div>
                </div>
            </slot>
        </div>
        <div class="card rounded-3">
            <div class="card-header" :class="classHeader" v-if="title">
                <h5>{{title}}</h5>
            </div>
            <div class="card-body">
                <pre-loader v-show="loader" class="position-absolute" :texto="loaderText"></pre-loader>
                <slot name="contenido">
                    <p>todo lo que esl body</p>
                </slot>
            </div>
            <div class="card-footer" v-if="$slots.footer">
                <slot name="footer">
                    <p>El footer del card</p>
                </slot>
            </div>
        </div>
    </div>
</template>
<script setup>
import { defineProps, inject } from 'vue'
import PreLoader from '@/components/public/template/PreLoader'
const loader = inject('loader')
defineProps({
    title:{
        type: String,
        default: null
    },
    classHeader:{
        type: String,
        default: null
    },
    loaderText: {
        type: String,
        default: 'Cargando...'
    }
})
</script>