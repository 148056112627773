<template>
    <div class="mb-5">
        <template-container title="Datos de la noticia">
            <template v-slot:contenido>
                <div class="d-flex align-item-end flex-row flex-wrap">
                    <inputs-validation class="col-12 col-sm-12" errorMsg="Por favor ingrese el título." :isInvalid="$v.nota.titulo.$invalid && send ? true : false" label="Titulo" v-model="nota.titulo"></inputs-validation>
                    <input-text-area-validation class="col-12 col-sm-12 my-3" id="pieFoto" label="Pie foto" v-model="nota.pie_foto" :isInvalid="$v.nota.pie_foto.$invalid && send ? true : false" errorMsg="Por favor ingrese el texto que estara abajo de la foto." ></input-text-area-validation>
                    <!-- <input-text-area-validation rows="5" class="col-12 col-sm-12 mb-3" label="Texto" v-model="nota.texto" :isInvalid="$v.nota.texto.$invalid && send ? true : false" errorMsg="Por favor ingrese el texto." ></input-text-area-validation> -->
                    <div class="col-12 my-3 d-flex flex-column" v-if="nota.texto">
                        <label for="" class="text-muted fw-bolder">Texto</label>
                        <QuillEditor style="min-height: 150px" theme="snow" :toolbar="toolbar" contentType="html" v-model:content="nota.texto" :class="$v.nota.texto.$invalid && send ? 'ql-container ql-snow is-invalid border-danger border-1' : 'ql-container ql-snow'" />
                        <div class="invalid-feedback" v-if="$v.nota.texto.$invalid && send">
                            <i class="fa fa-warning pe-2"></i>Por favor ingrese el contenido de la noticia.
                        </div>
                    </div>
                </div>
            </template>
        </template-container>
        <template-container title="Categorias">
            <template v-slot:contenido>
                <div class="d-flex align-item-end flex-column flex-wrap">
                    <div class="d-flex flex-row">
                        <input-select-validation @change="agregarCategoria" class="col-12 col-sm-6" label="Categoria" v-model="categoria" :datas="categorias"></input-select-validation>
                        <input-select-validation @change="agregarSubCategoria" v-if="categoria" class="col-12 col-sm-6" label="Subcategoria" v-model="subcategoria" :datas="categorias.find((e)=> e.id == categoria).subcategoria"></input-select-validation>
                    </div>
                    <p class="text-muted mt-3 col-12 d-flex ps-1 mb-0">Categorias agreagadas :</p>
                    <!-- <data-list :fields="fields" :datas="nota.categorias"></data-list> -->
                    <table class="table tabla-responsiva">
                        <thead>
                            <tr>
                                <th>Categorias</th>
                                <th>Subcategorias</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(categoria, i) in nota.categorias" :key="i" class="hovered" role="button">                
                                <td @click="deleteCategoria(categoria)">
                                    <span class="fw-bold me-1 px-3"><small>{{categoria.descripcion}}</small></span>                                        
                                </td>
                                <td>
                                    <template v-for="(subcategoria, b) in categoria.subcategorias" :key="b">
                                        <span class="fw-bold d-flex flex-column col-12 me-1 px-3" @click="deleteSubcategoria(subcategoria, categoria.id)"><small>{{subcategoria.descripcion}}</small></span>                                        
                                    </template>
                                </td>                            
                            </tr>
                        </tbody>
                    </table>
                </div>
            </template>
        </template-container>
        <template-container title="Imagenes">
            <template v-slot:contenido>
                <div class="d-flex align-item-end flex-row flex-wrap">
                    <load-images v-model="nota.imagenes" @eliminar="deleteFile($event)"></load-images>
                </div>
            </template>
        </template-container>
        <template-container>
            <template v-slot:contenido>
                <div class="d-flex justify-content-end flex-column flex-sm-row gap-2">
                    <btn-loading class="btn-danger" texto="Eliminar" icon="fa fa-trash" :sending="loadingEliminar" :disabled="loadingEliminar" @click="eliminarNota"></btn-loading>
                    <button class="btn btn-secondary" @click="goPrevisualizar"><i class="fa fa-eye"></i> Previsualizar</button>
                    <btn-loading class="btn-primary" icon="fa fa-plus" :sending="loading" :disabled="loading" @click="editarNota"></btn-loading>
                </div>
            </template>
        </template-container>
    </div>
</template>
<script setup>
import TemplateContainer from '@/components/admin/template/TemplateContainer'
import InputsValidation from '@/components/admin/template/InputsValidation'
import InputTextAreaValidation from '@/components/admin/template/InputTextAreaValidation'
import InputSelectValidation from '@/components/admin/template/InputSelectValidation'
// import DataList from '@/components/admin/template/DataList'
import BtnLoading from '@/components/admin/template/BtnLoading'
import LoadImages from '@/components/admin/template/LoadImages'
import BasicRequest from '@/utils/basic-request'
import Alert from '@/utils/alerts'
import { ref, watchEffect } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import {$v as validations} from '@/components/admin/nota/validations'
import {Delay} from '@/utils/delay'

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
/*vars*/
const nota = ref({});
const categoria = ref();
const categorias = ref({});
const subcategoria = ref(null);
// const deletingCategoria = ref({});
const send = ref(false);
const loading = ref(false);
const loadingEliminar = ref(false);
const router = useRouter();
const route = useRoute();
const toolbar = [
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote'],
        // [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }],
        [{ indent: '-1' }, { indent: '+1' }],
        [{ direction: 'rtl' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        // [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        [{ align: [] }],
        ['clean'], // remove formatting button
]

// const fields = [
//     {
//         titulo: 'Categoria',
//         key: 'categorias.descripcion',
//         classTitulo: 'text-primary',
//         classKey: 'fw-bold'
//     },
//     {
//         titulo: 'Subcategorias',
//         key: 'categorias.subcategorias.descripcion',
//         classTitulo: 'text-primary',
//         classKey: 'fw-bold d-flex flex-column col-12'
//     }
// ]

/*validations*/
const $v = validations({nota});

/*functions*/

const editarNota = ()=>{
    send.value = true
    if($v.value.nota.$invalid)
        return false
    loading.value = true;
    BasicRequest.putData('notas/'+nota.value.slug, nota.value)
    .then(()=>{
        Alert.Success({text: 'Noticia modificada con éxito'}).then(()=> router.push( {name: 'ListaNotas'} ) )
    })
    .catch(()=> Alert.Error({text: 'Error al modificar Noticia'}) )
    .finally(()=> loading.value = false )
}
const eliminarNota = ()=>{
    Alert.Option({ icon: 'warning', html: '<span>Estas a punto de eliminar esta nota.</span></br><span>¿Deseas continuar?</span>'})
    .then((response)=>{
        if(response.isConfirmed){
            loadingEliminar.value = true;
            BasicRequest.deleteData('notas/'+nota.value.slug)
            .then(()=>{
                Alert.Success({text: 'Noticia eliminada con éxito'}).then(()=> router.push( {name: 'ListaNotas'} ) )
            })
            .catch(()=> Alert.Error({text: 'Error al eliminar nota'}) )
            .finally(()=> loadingEliminar.value = false )
        }
    })
}
const deleteFile = async (data)=>{
    await BasicRequest.deleteData('imagen-nota/'+data.id)
    .then(()=>{
        nota.value.imagenes = nota.value.imagenes.filter((e)=> e.id != data.id)
    })
    .catch(()=>{
        Alert.Error({text: 'Error al eliminar archivo'})
    })
}

const getNota = async ()=>{
    await BasicRequest.getData('notas/'+route.params.id).then((e)=>{
        nota.value = e.nota
    })
}
getNota();
const getCategorias = async ()=>{
    await BasicRequest.getData('categorias').then( e => categorias.value = e.categorias)
}
getCategorias();

const goPrevisualizar = ()=>{
    Alert.Option({ 
        icon: 'info', 
        confirmButtonColor: "#198754",
        confirmButtonText: "Continuar",
        html: '<span>Se abrira una ventana para visualizar la noticia.</span></br><span>Tenga en cuenta que no se guardara hasta que presione el boton "guardar"</span>'
        })
    .then((response)=>{
        if(response.isConfirmed){
            let ruta = router.resolve({ name: 'PrevisualizarNota' });
            window.open(ruta.href, '_blank');           
        }
    })
}

const agregarCategoria = ()=>{
    let searchCategoria = categorias.value.find((elemento)=> elemento.id == categoria.value)
    subcategoria.value = null //seteo a null para que tome el onchange del select
    if(!searchCategoria)
        return false
    let categoriaEnNotas = nota.value.categorias.find((categ)=> categ.id == categoria.value);
    if(!categoriaEnNotas)
        nota.value.categorias.push({id: searchCategoria.id, descripcion: searchCategoria.descripcion, subcategorias:[] })
    mostrarOcultarCategoriasSelect()
}
const agregarSubCategoria = ()=>{
    let categoriaSeleccionada = categorias.value.find(( categ )=> categ.id == categoria.value ).subcategoria
    let subcategoriaSeleccionada = categoriaSeleccionada.find( (subCateg) => subCateg.id == subcategoria.value)
    if(subcategoriaSeleccionada)
        nota.value.categorias.find( (categ) => categ.id == categoria.value ).subcategorias.push({id: subcategoriaSeleccionada.id, descripcion: subcategoriaSeleccionada.descripcion })
}

const mostrarOcultarCategoriasSelect = async ()=>{    
    let getCategoria = nota.value.categorias.find( (categ) => categ.id == categoria.value );
    await Delay.awaitFunction(300)
    let selectSubCategorias = document.querySelector("#Subcategoria");
    if(selectSubCategorias){
        [...selectSubCategorias.options].map((option)=>{
            if(getCategoria.subcategorias.find( (subCateg) => subCateg.id == option.value))
                option.style.display = 'none'
            else
                option.style.display = ''
        })
    }
}

const deleteSubcategoria = (data, categoriaId)=>{
    try{
        Alert.Option({ icon: 'warning', html: '<span>Estas a punto de eliminar esta Subcategoría.</span></br><span>¿Deseas continuar?</span>'})
        .then( async (response)=>{
            if(response.isConfirmed){
                if(data.pivot)
                await BasicRequest.deleteData('nota-subcategoria/'+data.pivot.id)
                nota.value.categorias.find(( cat )=> cat.id == categoriaId ).subcategorias = nota.value.categorias.find(( cat )=> cat.id == categoriaId ).subcategorias.filter( (categ) => categ.id != data.id )
                subcategoria.value = null
            }
        })

    }catch(error){
        Alert.Error({text: 'Error al eliminar la categoria'})
    }
}
const deleteCategoria = (data)=>{
    try{
        Alert.Option({ icon: 'warning', html: '<span>Estas a punto de eliminar esta categoría.</span></br><span>Se eliminaran tambien todas sus subcategorias.</span></br><span>¿Deseas continuar?</span>'})
        .then( async (response)=>{
            if(response.isConfirmed){
                if(data.pivot)
                await BasicRequest.deleteData('nota-categoria/'+data.pivot.id)
                nota.value.categorias = nota.value.categorias.filter( (categ) => categ.id != data.id )
                categoria.value = null
            }
        })

    }catch(error){
        Alert.Error({text: 'Error al eliminar la categoria'})
    }
}

/* watchers */

watchEffect(()=>{
    if(nota.value)
        localStorage.setItem('nota', JSON.stringify(nota.value));
    else
        localStorage.removeItem('nota')

    if(nota.value.categorias)
        mostrarOcultarCategoriasSelect()
    if(categoria.value)
        mostrarOcultarCategoriasSelect()
})
</script>