<template>
    <div id="carouselExampleIndicators" class="carousel slide h-100" data-bs-ride="carousel">
        <div class="carousel-indicators z-index-10">
            <template v-for="(image, index) in images" :key="index">
                <button type="button" data-bs-target="#carouselExampleIndicators" :data-bs-slide-to="index" :class="index==0 ? 'active' : ''" aria-current="true" :aria-label="'Slide '+index"></button>
            </template>
        </div>
        <div class="carousel-inner h-100">
            <div class="carousel-item h-100" :class="index==0 ? 'active' : ''" v-for="(image, index) in images" :key="index">
                <img :src="backend+'/'+image.ruta" @error="imageError" alt="Imagen carousel" class="img-scale-down" style="object-fit: cover!important;">
                <!-- <img :src="backend+'/'+image.ruta" @error="imageError" alt="" class="img-fondo"> -->
            </div>
            <div class="carousel-item active h-100" v-if="!images || images.length == 0">
                <img src="@/assets/no-image-1.png" alt="No imagen available" class="img-scale-down">
                <img src="@/assets/no-image-1.png" alt="No imagen available" class="img-fondo">
            </div> 
        </div>
        <button class="carousel-control-prev z-index-10" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" v-show="images && images.length > 1">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next z-index-10" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" v-show="images && images.length > 1">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>
</template>
<script setup>
import { defineProps } from 'vue'
defineProps({
    images: {
        type: Object,
        default: ()=>{
            return {}
        }
    },
})
const backend = process.env.VUE_APP_URL_IMAGES;
const imageError = (event)=>{
    event.target.src = require('@/assets/no-image-1.png')
}
</script>