<template>
    <div class="vh-100 col-12 d-flex align-items-center justify-content-center px-3">
        <!-- <pre-loader v-if="loading" ></pre-loader> -->
        <pre-sending v-if="loading" texto="Aguarde. Estamos enviando un email a su correo"></pre-sending>
        <form @submit.prevent="login" class="col-sm-5 col-12">
            <h2 class="text-muted pb-2"> Ingrese su dirección de correo eléctronico</h2>
            <div class="mb-2">
                <label for="" class="text-muted fw-bold"><i class="fa-solid fa-envelope"></i> Email</label>
                <input type="email" v-model="user.email" class="form-control" placeholder="Ingrese su email" :class="v.email.$invalid && send ? 'is-invalid' : ''">
                <div class="invalid-feedback">
                    <i class="fa fa-warning pe-2"></i>Debe ingresar un email válido
                </div>
            </div>
            <div class="col-12 justify-content-end d-flex flex-column-reverse flex-sm-row mt-2">
                <button type="submit" class="boton boton-violeta px-2"><i class="fa-solid fa-check me-2"></i> Iniciar recuperación</button>
            </div>
        </form>
    </div>
</template>
<style scoped src="@/assets/publico/style.css"></style>
<script setup>
import { ref } from 'vue'
import Alert from '@/utils/alerts'
import BasicRequest from '@/utils/basic-request'
import { useRouter } from 'vue-router'
import PreSending from '@/components/public/template/PreSending'
import useVuelidate from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'

/* vars */
const loading = ref(false);
const router = useRouter();
const user = ref({});
const send = ref(false);
const rules = {
    email: { required, email }
}
const v = useVuelidate(rules, user);

/* functions */
const login = async ()=>{
    send.value = true
    if(v.value.$invalid){
        return false
    }
    loading.value = true
    await BasicRequest.postData('forgot-password', user.value)
    .then(async () => {
        loading.value = false
        Alert.Close()
        Alert.Success({ titles:'Su solicitud se ha enviado con éxito',
        html: `
        <p class="text-muted">Recibirá un correo electrónico con el enlace y código de restablecimiento de contraseña.</p>
        <p class="text-muted">Por favor, verifique su casilla de email y correos no deseados.</p>
        `}).then(()=>{
            router.push({ name: 'LoginView'})
        })
    })
    .catch((e)=> {
        loading.value = false
        Alert.Error({ titles:'Error...', 
        html: `
        <p class="text-muted">No se pudo enviar la solicitud de reestablecimiento.</p>
        <p class="text-muted"> - ${e.response.data.message} -</p>
        `})
    })
    
}
</script>
