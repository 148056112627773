<template>
     <template-container title="Listado de formularios" class="mb-5">
        <template v-slot:top>
            <div class="col-12 d-flex justify-content-end">
                <router-link :to="{ name: 'CreateFormulario' }" class="btn btn-primary"><i class="fa fa-plus"></i> Nuevo Formulario </router-link>
            </div>
        </template>
        <template v-slot:contenido>
            <div>
                <input-search id="search" class="col-12 col-sm-6" v-model="search" :cargando="loading"></input-search>   
                <data-list :fields="fields" :datas="formularios.data" @evento="router.push({ name: 'EditFormulario', params: {id: $event.id} })"></data-list>
                <p class="text-center" v-if="formularios.data && formularios.data.length <= 0">No hay datos...</p>
                <div class="d-flex justify-content-center" v-if="formularios.data && formularios.data.length > 0">
                    <template-paginate :pages="formularios" @changePage="getFormularios($event)"></template-paginate>
                </div>
            </div>
        </template>
    </template-container>
</template>
<script setup>
import TemplateContainer from '@/components/admin/template/TemplateContainer'
import TemplatePaginate from '@/components/admin/template/TemplatePaginate'
import DataList from '@/components/admin/template/DataList'
import InputSearch from '@/components/admin/template/InputSearch'
import BasicRequest from '@/utils/basic-request'
import { ref, watchEffect, watch } from 'vue'
import Fecha from '@/utils/fecha'
import { Delay } from '@/utils/delay'
import { useRouter } from 'vue-router'

/*vars*/
const formularios = ref({});
const loading = ref(false)
const search = ref();
const router = useRouter();
const fields = [
    {
        titulo: 'Titulo',
        key: 'titulo',
        classTitulo: 'text-primary',
        classKey: 'fw-bold'
    },
    {
        titulo: 'Descripción:',
        key: 'descripcion',
        classTitulo: 'text-muted',
        classKey: 'text-muted fw-bold fw-sm-normal',
    },
    {
        titulo: 'Estado:',
        key: 'estado',
        classTitulo: 'text-muted',
        classPerKey: []
    },
    {
        titulo: 'Fecha:',
        key: 'created_at',
        classTitulo: 'text-muted',
        classKey: 'text-muted fw-bold fw-sm-normal',
    }
]

/* functions */
const getFormularios = async (page, searchParam) =>{
    await BasicRequest.getData('formularios?page='+page, {params:{paginate:5, searchParam: searchParam}})
    .then( e => formularios.value = e.formularios )
}
getFormularios('0', '')
const busquedaConDelay = async ()=>{
    loading.value = true;
    await Delay.awaitFunction(2000)
    .then(() => {
        loading.value = false
        getFormularios('0', search.value) 
    })
}

/* watchers */
watchEffect(()=>{
    if(formularios.value.data){
        formularios.value.data.filter((e)=> e.created_at = Fecha.formatDate(e.created_at, "DD/MM/YYYY"))
        formularios.value.data.filter((e, i)=> e.estado == 'Desbloqueado' ? fields[2].classPerKey[i] = 'fw-bold fw-sm-normal text-success' : fields[2].classPerKey[i] = 'fw-bold fw-sm-normal text-danger')
    }
})
watch(search, ()=> busquedaConDelay() )

</script>
