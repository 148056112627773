import PrivateIndex from '@/components/admin/PrivateIndex'
import CreateUsuario from '@/components/admin/usuario/CreateUsuario'
import ListarUsuarios from '@/components/admin/usuario/ListarUsuarios'
import VerUsuario from '@/components/admin/usuario/VerUsuario'
import VerPerfil from '@/components/admin/usuario/MiPerfil'
import CambiarPassword from '@/components/admin/usuario/CambiarPassword'

/* notas */
import CreateNota from '@/components/admin/nota/CreateNota'
import ListaNotas from '@/components/admin/nota/ListaNotas'
import EditNota from '@/components/admin/nota/EditNota'

/* dias calendario */
import ListarDiasCalendario from '@/components/admin/diascalendario/ListarDiasCalendario'
import CreateDiaCalendario from '@/components/admin/diascalendario/CreateDiaCalendario'
import EditDiaCalendario from '@/components/admin/diascalendario/EditDiaCalendario'

/* categorias */
import ListarCategorias from '@/components/admin/categoria/ListarCategorias'
import EditCategoria from '@/components/admin/categoria/EditCategoria'
import CreateCategoria from '@/components/admin/categoria/CreateCategoria'

/* subcategorias */
import ListarSubCategorias from '@/components/admin/subcategoria/ListarSubCategorias'
import EditSubCategoria from '@/components/admin/subcategoria/EditSubCategoria'
import CreateSubCategoria from '@/components/admin/subcategoria/CreateSubCategoria'

/* formularios */
import ListarFormularios from '@/components/admin/formulario/ListarFormularios'
import CreateFormulario from '@/components/admin/formulario/CreateFormulario'
import EditFormulario from '@/components/admin/formulario/EditFormulario'

/* galeria */
import ListarGaleria from '@/components/admin/galeria/ListarGaleria'
import CreateGaleria from '@/components/admin/galeria/CreateGaleria'
import EditGaleria from '@/components/admin/galeria/EditGaleria'

const galeria = [
    {
        path: "/listar/galeria",
        name:"ListarGaleria",
        component: ListarGaleria,
        meta: {
            private:true,
            admin: true
        },
        btn:{
            icon: 'fa fa-image',
            text: 'Galeria',
        }
    },
    {
        path: "/crear/galeria",
        name:"CreateGaleria",
        component: CreateGaleria,
        meta: {
            private:true,
            admin: true
        }
    },
    {
        path: "/editar/galeria/:id",
        name:"EditGaleria",
        component: EditGaleria,
        meta: {
            private:true,
            admin: true
        }
    }
]

const formulario = [
    {
        path: "/listar/formularios",
        name:"ListarFormularios",
        component: ListarFormularios,
        meta: {
            private:true,
            admin: true
        },
        btn:{
            icon: 'fa fa-download',
            text: 'Formularios',
        }
    },
    {
        path: "/crear/formulario",
        name:"CreateFormulario",
        component: CreateFormulario,
        meta: {
            private:true,
            admin: true
        }
    },
    {
        path: "/editar/formulario/:id",
        name:"EditFormulario",
        component: EditFormulario,
        meta: {
            private:true,
            admin: true
        }
    }
]

const categorias = [
    {
        path: "/listar/categorias",
        name:"ListarCategorias",
        component: ListarCategorias,
        meta: {
            private:true,
            admin: true
        },
        btn:{
            icon: 'fa fa-list',
            text: 'Categorías',
        }
    },
    {
        path: "/ver/categoria/:id",
        name:"EditCategoria",
        component: EditCategoria,
        meta: {
            private:true,
            admin: true
        }
    },
    {
        path: "/crear/categoria",
        name:"CreateCategoria",
        component: CreateCategoria,
        meta: {
            private:true,
            admin: true
        }
    }
]

const subCategorias = [
    {
        path: "/listar/subcategorias",
        name:"ListarSubCategorias",
        component: ListarSubCategorias,
        meta: {
            private:true,
            admin: true
        },
        btn:{
            icon: 'fa fa-list',
            text: 'Subcategorías',
        }
    },
    {
        path: "/ver/subcategoria/:id",
        name:"EditSubCategoria",
        component: EditSubCategoria,
        meta: {
            private:true,
            admin: true
        }
    },
    {
        path: "/crear/subcategoria",
        name:"CreateSubCategoria",
        component: CreateSubCategoria,
        meta: {
            private:true,
            admin: true
        }
    }
]

const diasCalendario = [
    {
        path: "/ver/dia-festivo/:id",
        name:"EditDiaCalendario",
        component: EditDiaCalendario,
        meta: {
            private:true,
            admin: true
        },
    },
    {
        path: "/crear/dia-festivo",
        name:"CreateDiaCalendario",
        component: CreateDiaCalendario,
        meta: {
            private:true,
            admin: true
        },
    },
    {
        path: "/listar/dias-calendario",
        name:"ListarDiasCalendario",
        component: ListarDiasCalendario,
        meta: {
            private:true,
            admin: true
        },
        btn:{
            icon: 'fa fa-calendar',
            text: 'Días Calendario',
        }
    }
]

const nota = [
    {
        path: "/crear/noticia",
        name:"CreateNota",
        component: CreateNota,
        meta: {
            private:true,
            admin: true
        }
    },
    {
        path: "/editar/noticia/:id",
        name:"EditNota",
        component: EditNota,
        meta: {
            private:true,
            admin: true
        }
    },
    {
        path: "/listado/noticias",
        name:"ListaNotas",
        component: ListaNotas,
        meta: {
            private:true,
            admin: true
        },
        btn:{
            icon: 'fa fa-file-lines',
            text: 'Noticias',
        }
    }
]
const usuario = [
    {
        path: "/password",
        name:"CambiarPassword",
        component: CambiarPassword,
    },
    {
        path: "/mi-perfil",
        name:"VerPerfil",
        component: VerPerfil,
    },
    {
        path: "/crear/usuario",
        name:"CreateUsuario",
        component: CreateUsuario,
        meta: {
            private:true,
            admin: true
        }
    },
    {
        path: "/ver/usuario/:id",
        name:"VerUsuario",
        component: VerUsuario,
        meta: {
            private:true,
            admin: true
        }
    },
    {
        path: "/usuarios",
        name:"ListarUsuarios",
        component: ListarUsuarios,
        meta:{
            private:true,
            admin: true
        },
        btn:{
            icon: 'fa fa-users',
            text: 'Listar Usuarios',
        }
    },
]


export const AdminRoutes = [
    {
        path: "/admin",
        name:"PrivateIndex",
        component: PrivateIndex,
        // redirect: {name: 'Bienvenida'},
        meta: {
            private:true,
            admin: true,
        },
        children:[
            ...usuario,
            ...nota,
            ...diasCalendario,
            ...categorias,
            ...subCategorias,
            ...formulario,
            ...galeria,
        ]
    },
]

