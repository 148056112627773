<template>
    <nav aria-label="...">
        <ul class="pagination" v-if="pages">
            <template v-for="(page, i) in pages.links" :key="i">
                    <li role="button" :class="!page.url ? 'disabled' : ''" class="page-item" v-if="i== 0">
                        <span class="page-link" @click="emitPage(page.url)">Anterior</span>
                    </li>
                    <!-- <p>/{{pages.current_page}} + {{pagesView(pages.current_page)}}/</p> -->
                    <template v-if="i >= pages.current_page - 2 && i <= pages.current_page + pagesView(pages.current_page)">
                        <li role="button" :class="page.active ? 'active' : ''" class="page-item" v-if="i > 0 && i != pages.links.length-1">
                            <a class="page-link" @click="emitPage(page.url)">{{page.label}}</a>
                        </li>
                    </template>
                    <li role="button" :class="!page.url ? 'disabled' : ''" class="page-item" v-if=" i == pages.links.length-1">
                        <a class="page-link" @click="emitPage(page.url)">Siguiente</a>
                    </li>
            </template>
        </ul>
    </nav>
</template>
<script setup>
import { defineProps, defineEmits } from 'vue'
const props = defineProps({
    pages: {},
    paginasMuesta: {
        type: Number,
        default: 5
    }
})
const emit = defineEmits(['changePage'])
const emitPage = (page)=>{
    if(!page)
        return false
    page = page.split('=')[1]
    emit('changePage', page)
}
const pagesView = (index)=>{
    if(index == 1 )
        return props.paginasMuesta -1
    if(index == 2 )
        return props.paginasMuesta -2 
    return props.paginasMuesta -3
}
</script>