<template>
    <header>
        <nav class="px-3 container pb-3">
            <router-link :to=" {name: 'PublicIndex'} " class="text-decoration-none">
                <div class="logo-section align-items-end">
                    <div class="logo">
                            <img src="@/assets/publico/Escudo.png" alt="logo">
                    </div>
                    <div class="logo-info text-xs">
                        <p class="m-0">Instituto</p>
                        <p class="m-0"><b>Maria Auxiliadora</b></p>
                        <p class="m-0"><small>Santa Rosa - La Pampa - Argentina</small></p>
                    </div>
                </div>
            </router-link>
            <div class="d-sm-flex align-items-end justify-content-sm-end justify-content-between pe-sm-4 text-xs mb-1 d-none">
                <router-link :to="{ name: 'QuienesSomos' }" class="px-lg-3 px-1 text-decoration-none"><b class="text-muted">Quienes somos</b></router-link>
            </div>
            <div class="align-items-end justify-content-end d-none d-sm-flex">
                <router-link :to="{ name:'LoginView' }" class="boton boton-violeta mb-1 col-sm-9"><i class="fa-solid fa-arrow-right-to-bracket pe-2"></i> Ingresar</router-link>
            </div>
            <div class="d-flex d-sm-none justify-content-end">
                <button class="boton boton-tisa col-3" @click="actionMenu">
                    <div v-show="!hideMenu"><i class="fa-solid fa-bars"></i></div>
                    <div v-show="hideMenu"><i class="fa fa-times"></i></div>
                </button>
            </div>
            <transition name="fade">
                <template-menu v-if="hideMenu" @click="actionMenu" class="col-12 pt-1"></template-menu>
            </transition>
        </nav>
    </header>
</template>
<style scoped src="@/assets/publico/header.css"></style>
<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
  background-color: var(--header);
  height: auto;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    height: 0px;
}
</style>
<script setup>
import TemplateMenu from '@/components/public/template/TemplateMenu'
import { ref } from 'vue'
const hideMenu = ref(false)
const actionMenu = ()=>{
    if(hideMenu.value)
        return hideMenu.value = false
    return hideMenu.value = true
}
</script>
