<template>
    <div>
        <div v-for="galeria in galerias" :key="galeria.id">
            <!-- <router-link :to="{ name: 'ListarGaleriaFront'}" class="text-decoration-none"> -->
                <div class="nota col-sm-12 col-12 mb-4" role="button" @click="viewGaleria(galeria)">
                    <div class="col-12 imagen-nota">
                        <div class="carousel-inner h-100">
                            <div class="carousel-item h-100" :class="index==0 ? 'active' : ''" v-for="(image, index) in galeria.imagenes" :key="index">
                                <img :src="backend+'/'+image.ruta" @error="imageError" :alt="galeria.titulo" class="img-scale-down">
                                <img :src="backend+'/'+image.ruta" @error="imageError" :alt="galeria.titulo" class="img-fondo">
                            </div>
                            <div class="carousel-item active h-100" v-if="galeria.imagenes && galeria.imagenes.length == 0">
                                <img src="@/assets/no-image-1.png" alt="No image available" class="img-scale-down">
                                <img src="@/assets/no-image-1.png" alt="No image available" class="img-fondo">
                            </div>
                        </div>
                        <div class="radial-gradiente"></div>
                        <div class="gradiente"></div>
                    </div>
                    <div class="col-12 test">
                        <div class="">
                            <span class="elipsis-2 pe-2 titulo-galeria" v-html="galeria.titulo">
                            </span>
                        </div>
                    </div>
                </div>
            <!-- </router-link> -->
        </div>
            <ver-galeria v-if="ver" :descripcion="ver.descripcion" :titulo="ver.titulo" :imagenes="ver.imagenes" @cerrar="()=> ver=null"></ver-galeria>
    </div>
</template>
<style scoped src="@/assets/publico/mini-galeria.css"></style>

<script setup>
import { defineProps, ref } from 'vue'
import VerGaleria from '@/components/public/galeria/VerGaleria'

/* props */
defineProps({
    galerias: {
        type: Object,
        default: function(){
            return [];
        }
    }
})

/* vars */
const backend = process.env.VUE_APP_URL_IMAGES;
const ver = ref([]);


/*functions*/
const imageError = (event)=>{
    event.target.src = require('@/assets/no-image-1.png')
}
const viewGaleria = (data) => {
    ver.value = data
}
</script>