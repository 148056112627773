<template>
     <template-container title="Listado usuarios" class="mb-5">
        <template v-slot:top>
            <div class="col-12 d-flex justify-content-end">
                <router-link :to="{ name: 'CreateUsuario' }" class="btn btn-primary"><i class="fa fa-user-plus"></i> Nuevo Usuario </router-link>
            </div>
        </template>
        <template v-slot:contenido>
            <div>
                <data-list :fields="fields" :datas="users.data" @evento="router.push({ name: 'VerUsuario', params: {id: $event.id }})"></data-list>
                <div class="d-flex justify-content-center">
                    <template-paginate :pages="users" @changePage="getUsers($event)"></template-paginate>
                </div>
            </div>
        </template>
    </template-container>
</template>
<script setup>
import TemplateContainer from '@/components/admin/template/TemplateContainer'
import BasicRequest from '@/utils/basic-request'
import DataList from '@/components/admin/template/DataList'
import { ref } from 'vue'
import TemplatePaginate from '@/components/admin/template/TemplatePaginate'
import { useRouter } from 'vue-router'

/* vars */
const fields = [
    {
        titulo: 'Nombre',
        key: 'nombre',
        classTitulo: 'text-primary',
        classKey: 'fw-bold'
    },
    {
        titulo: 'Apellido:',
        key: 'apellido',
        classTitulo: 'text-muted',
        classKey: 'text-muted fw-bold fw-sm-normal',
    },
    {
        titulo: 'Email:',
        key: 'email',
        classTitulo: 'text-muted',
        classKey: 'text-muted fw-bold',
    }
]
const router = useRouter();
const users = ref({});

/* functions */
const getUsers = (page) =>{
    BasicRequest.getData('users?page='+page, {params:{paginate:5}}).then((e)=>{
        users.value = e.users
    })
}
getUsers('0')
</script>
