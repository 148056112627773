<template>
    <div class="background-grey">
        <template-header></template-header>
        <section class="px-3 container">
            <div class="col row mt-2">
                <div class="col diasFestivos me-3">              
                    <div class="col row" v-for="diaFestivo in diasFestivos" :key="diaFestivo.id">
                        <span class="col my-0 fw-bolder text-muted">{{diaFestivo.dia}} de {{Fecha.getMesString(diaFestivo.mes)}}</span>
                        <span class="col text-end my-0 descripcion fw-bolder text-muted">{{diaFestivo.descripcion}} - {{diaFestivo.tipo}}</span>
                    </div>              
                </div>
                <div class="col-sm-2 mt-2 mt-sm-0">
                    <input-search class="col-12 col-sm-12" claseInput="rounded-0" v-model="search" :cargando="loadingButton"></input-search>
                </div>
            </div>
        </section>
        <section class="px-3 container-lg mt-3">
            <div class="d-flex justify-content-between mt-2">
                <div class="col-sm-10 col-12">
                    <Transition name="fade">
                        <router-view></router-view>
                    </Transition>
                </div>
                <div class="columna-izquierda col-sm-2 col-12 mt-lg-5 pt-lg-3 border-start border-3 d-none d-sm-block">
                    <template-menu class="overflow-hidden" nameComponent='ListarNotasPorCategoria'></template-menu>
                    <div class="imagenes mt-3 ps-3" v-if="galerias && galerias.length > 0">
                        <!-- <h5 class="text-muted text-center mt-5 py-2 border-galeria position-relative">Galerias</h5> -->
                        <div class="position-relative titulo-galeria border-galeria mb-3 mt-4">
                            <router-link class="text-decoration-none elipsis pe-3" :to="{ name: 'ListarGaleriaFront'}">
                                <span class="py-2 text-violeta position-relative">
                                    Galeria de fotos
                                    <i class="fa fa-angle-right"></i>
                                </span>
                            </router-link>
                        </div>
                        <mini-galeria :galerias="galerias"></mini-galeria>
                    </div>
                </div>
            </div>
        </section>
        <template-footer></template-footer>
    </div>
</template>
<style scoped src="@/assets/publico/style.css"></style>
<script setup>
import TemplateHeader from '@/components/public/template/TemplateHeader'
// import MiniNotas from '@/components/public/template/MiniNotas'
import TemplateMenu from '@/components/public/template/TemplateMenu'
import TemplateFooter from '@/components/public/template/TemplateFooter'
import MiniGaleria from '@/components/public/template/MiniGaleria'
import InputSearch from '@/components/admin/template/InputSearch'
import BasicRequest from '@/utils/basic-request'
import { ref, provide } from 'vue'
import moment from 'moment';
import Fecha from '@/utils/fecha'

/* vars */
const search = ref(null);
const loadingButton = ref(false)
provide('loadingButton', loadingButton);
provide('search', search);
const diasFestivos = ref({});
const mes = ref(moment().locale('es').format("MM"))
const dia = ref(moment().locale('es').format("DD"))

const galerias = ref();

/* functions */
const getDiaFestivo = async () =>{
    await BasicRequest.getData('get-diasfestivos', {params:{dia:dia.value, mes:mes.value}})
    .then( e => diasFestivos.value = e.diasFestivos )
}
getDiaFestivo()

const getGalerias = (page, searchParam) =>{
    BasicRequest.getData('get-galeria?page='+page, {params:{paginate:5, searchParam: searchParam}})
    .then( e => galerias.value = e.galerias.data.slice(0,6) )
}
getGalerias('0', '')

</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>