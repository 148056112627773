<template>
    <form @submit.prevent="createSubCategoria" class="mb-4">
        <template-container title="Crear subcategoría">
            <template v-slot:contenido>
                <div class="d-flex align-item-end flex-row flex-wrap">
                    <inputs-validation class="col-12 col-sm-12" placeholder="Ingrese la Descripción..." label="Descripción" v-model="subcategoria.descripcion" :isInvalid="$v.subcategoria.descripcion.$invalid && send ? true : false"></inputs-validation>
                </div>
                <!-- <div class="d-flex align-item-end flex-row flex-wrap my-3">
                    <input-select-validation @change="agregarCategoria" class="col-12 col-sm-12" placeholder="Seleccione la Categoria..." label="Categoria perteneciente" v-model="subcategoria.categoria_id" :datas="categorias" :isInvalid="$v.subcategoria.categoria_id.$invalid && send ? true : false"></input-select-validation>
                </div> -->
                <data-list-buttons class="my-3" title="Categorías pertenecientes:" label-select="Categorías" array-key="descripcion" :return-datas="subcategoria.categorias" :get-data="categorias"></data-list-buttons>
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-end">
                    <btn-loading type="submit" class="btn-primary" icon="fa fa-pencil" :sending="loading" :disabled="loading"></btn-loading>
                </div>
            </template>
        </template-container>
    </form>
</template>
<script setup>
import TemplateContainer from '@/components/admin/template/TemplateContainer'
import InputsValidation from '@/components/admin/template/InputsValidation'
// import InputSelectValidation from '@/components/admin/template/InputSelectValidation'
import BtnLoading from '@/components/admin/template/BtnLoading'
import DataListButtons from '@/components/admin/template/DataListButtons'
import BasicRequest from '@/utils/basic-request'
import Alert from '@/utils/alerts'
import {$v as validations} from '@/components/admin/subcategoria/validations'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
/* vars */
const subcategoria = ref({
    categorias: []
});
const categorias = ref({});
const send = ref(false);
const loading = ref(false);
const router = useRouter();

/*validations*/
const $v = validations({subcategoria});

/* functions */
const createSubCategoria = async ()=>{
    try{
        send.value = true
        if($v.value.subcategoria.$invalid)
            return false
        loading.value = true;
        await BasicRequest.postData('subcategorias', subcategoria.value)
        Alert.Success({text: 'Subcategoría creada con éxito'}).then(()=> router.push( {name: 'ListarSubCategorias'} ) )
        loading.value = false
    }catch(error){
        Alert.Error({text: 'Error al crear la subcategoría'})
        loading.value = false
    }
}
const getCategorias = ()=>{
    BasicRequest.getData('categorias').then((e)=>{
        categorias.value = e.categorias
    })
}
getCategorias();



</script>