import ListarNotas from '@/components/public/notas/ListarNotas'
import ListarNotasPorCategoria from '@/components/public/notas/ListarNotasPorCategoria'
import VerNota from '@/components/public/notas/VerNota'
import PublicIndex from '@/components/public/PublicIndex'

/* formularios */
import VerFormularioFront from '@/components/public/formulario/VerFormularioFront'
import ListarFormulariosFront from '@/components/public/formulario/ListarFormulariosFront'

/* quienes somos */
import QuienesSomos from '@/components/public/QuienesSomos.vue'

/* Galeria */
import ListarGaleriaFront from '@/components/public/galeria/ListarGaleria'

const galeria = [
    {
        path: "/galeria",
        name:"ListarGaleriaFront",
        component: ListarGaleriaFront,
    }
]
const quienessomos = [
    {
        path: "/quienes-somos",
        name:"QuienesSomos",
        component: QuienesSomos,
    }
]
const formulario = [
    {
        path: "/formulario/:id",
        name:"VerFormularioFront",
        component: VerFormularioFront,
    },
    {
        path: "/formularios",
        name:"ListarFormulariosFront",
        component: ListarFormulariosFront,
    }
]
const notas = [
    {
        path: "/nota/:id",
        name:"VerNota",
        component: VerNota,
    },
    {
        path: "/nota-categoria/:id",
        name:"ListarNotasPorCategoria",
        component: ListarNotasPorCategoria,
    },
    {
        path: "/nota-subcategoria/:subcategoria",
        name:"ListarNotasPorSubcategoria",
        component: ListarNotasPorCategoria,
    },
    {
        path: "/",
        name:"ListarNotas",
        component: ListarNotas,
    },
]
const index = [
    {
        path: "/",
        name:"PublicIndex",
        component: PublicIndex,
        redirect: {name: 'ListarNotas'},
        meta: {
            private:false,
            cliente: true
        },
        children:[
            ...notas,
            ...formulario,
            ...quienessomos,
            ...galeria
        ]
    }
    
]
export const PublicRoutes = index;