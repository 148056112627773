export const meses = [
    {
        id: 1,
        descripcion: 'Enero'
    },
    {
        id: 2,
        descripcion: 'Febrero'
    },
    {
        id: 3,
        descripcion: 'Marzo'
    },
    {
        id: 4,
        descripcion: 'Abril'
    },
    {
        id: 5,
        descripcion: 'Mayo'
    },
    {
        id: 6,
        descripcion: 'Junio'
    },
    {
        id: 7,
        descripcion: 'Julio'
    },
    {
        id: 8,
        descripcion: 'Agosto'
    },
    {
        id: 9,
        descripcion: 'Septiembre'
    },
    {
        id: 10,
        descripcion: 'Octubre'
    },
    {
        id: 11,
        descripcion: 'Noviembre'
    },
    {
        id: 12,
        descripcion: 'Diciembre'
    },
]