<template>
     <template-container title="Listado de categorías" class="mb-5">
        <template v-slot:top>
            <div class="col-12 d-flex justify-content-end">
                <router-link :to="{ name: 'CreateCategoria' }" class="btn btn-primary"><i class="fa fa-plus"></i> Nueva Categoría </router-link>
            </div>
        </template>
        <template v-slot:contenido>
            <div>
                <data-list :fields="fields" :datas="categorias.data" @evento="router.push({ name: 'EditCategoria', params: {id: $event.id }})"></data-list>
                <p class="text-center" v-if="categorias.data && categorias.data.length <= 0">No hay datos...</p>
                <div class="d-flex justify-content-center" v-if="categorias.data && categorias.data.length > 0">
                    <template-paginate :pages="categorias" @changePage="getCategorias($event)"></template-paginate>
                </div>
            </div>
        </template>
    </template-container>
</template>
<script setup>
import TemplateContainer from '@/components/admin/template/TemplateContainer'
import BasicRequest from '@/utils/basic-request'
import DataList from '@/components/admin/template/DataList'
import { ref } from 'vue'
import TemplatePaginate from '@/components/admin/template/TemplatePaginate'
import { useRouter } from 'vue-router'

/* vars */
const fields = [
    {
        titulo: 'Descripción',
        key: 'descripcion',
        classTitulo: 'text-primary',
        classKey: 'fw-bold'
    },
    {
        titulo: 'Subcategorias:',
        key: 'subcategoria.descripcion',
        classTitulo: 'text-muted',
        classKey: 'fw-bold d-flex flex-column col-12'
    }
]
const router = useRouter();
const categorias = ref({});
const loader = ref(true);

/* functions */
const getCategorias = async (page) =>{
    loader.value = true;
    await BasicRequest.getData('categorias?page='+page, {params:{paginate:5}}).then((e)=>{
        categorias.value = e.categorias
    }).finally(()=>{
        loader.value = false;
    })
}
getCategorias('0')
</script>
