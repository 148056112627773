import { defineStore } from 'pinia'
import Axios from 'axios'

export const UseAuthStore = defineStore('auth', {
    state: ()=>({
        token: null,
        isLoggin: null,
        usuario: null
    }),
    actions: {
        async login(data){
            return Axios.post("/login", data)
                .then((e)=>{
                    this.token = e.data.token;
                    this.isLoggin = true;
                    return e
                }).catch( (e) => { throw new Error(e) });
        },
        async getUserProfile(){
            const token = localStorage.getItem('token');
            Axios.defaults.headers.common['Authorization'] = 'Bearer '+token;
            return new Promise((resolve, reject)=>{
                Axios.get('/user-profile').then((e)=>{                    
                    this.usuario = e.data
                    this.isLoggin = true
                    return resolve(e)
                })
                .catch((error)=>{
                    this.$reset()
                    return reject(error)
                })
            })
        },
        async logout(){
            try{
                let response = await Axios.get("/logout")
                localStorage.removeItem('token')
                this.$reset()
                return response
            }catch(error){
                return error
            }
        }

    }
})
