<template>
    <router-view></router-view>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap/js/dist/carousel.js'
import '@/assets/fontawesome/css/all.css'
import '@/assets/fontawesome/js/all.js'
export default {
  name: 'App',
}
</script>

<style>
body{
    margin: 0;
    height: 100%;
}
:root {
    --header: #b5caeb;
    --violeta: #2c0880;
    --violeta-hover: #3b1b86;
    --tisa: #861B31;
    --tisa-hover: #863B1B;
    --violeta-eminence: #642C6C;
    --MediumPurple: #7052a5;
    /* --gris: rgb(244, 244, 242); */
    --gris: #f4f4f2;
    --bg-grey: rgb(233 233 233);
}
</style>
