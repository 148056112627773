<template>
    <div>
        <div class="titlo-nota mt-3 mb-4">
            <h2 class="fw-bolder text-violeta">Listado de Formularios</h2>
        </div>
        <section class="accordion_two_section">
            <div class="row">
                <div class="col-sm-11 accordionTwo">
                    <div class="panel-group" id="accordionTwoLeft">
                        <div class="panel panel-default" v-for="formulario in formularios" :Key="formulario.id">
                            <div class="panel-heading">
                                <h4 class="panel-title" title="Click para ver">
                                    <a data-bs-toggle="collapse" :href="'#collapse'+formulario.id" aria-expanded="false" class="collapsed text-muted text-decoration-none">
                                        {{formulario.titulo}}
                                    </a>
                                </h4>
                            </div>
                            <div :id="'collapse'+formulario.id" class="panel-collapse collapse" data-bs-parent="#accordionTwoLeft" aria-expanded="false" role="tablist" style="height: 0px;">
                                <div class="panel-body">
                                    <div>{{formulario.descripcion}}</div>
                                    <div class="col-12 mt-5">
                                        <p class="col-auto" style="white-space: pre-wrap;" v-if="formulario.estado == 'Desbloqueado'">
                                            <a :href="formulario.links.ruta" class="text-decoration-none fw-bolder"><i class="fa-solid fa-download" style="font-size:20px"></i> Click para ver {{formulario.titulo}}</a>
                                        </p>
                                    </div>
                                    <!-- <div class="col-12 mt-5" v-for="link in formulario.links" :key="link.id">
                                        <p class="col-auto" style="white-space: pre-wrap;" v-if="formulario.estado == 'Desbloqueado'">
                                            <a :href="link.ruta" class="text-decoration-none fw-bolder"><i class="fa-solid fa-download" style="font-size:20px"></i> Click para ver {{formulario.titulo}}</a>
                                        </p>
                                    </div> -->
                                    <p class="col-auto text-danger" style="white-space: pre-wrap;" v-if="formulario.estado == 'Bloqueado'">
                                        Por el momento no se encuentra disponible este formulario para su descarga.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<style scoped>
.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.accordionTwo .panel-group {
    margin-bottom: 0;
}

.accordionTwo .panel {
    background-color: transparent;
    box-shadow: none;
    border-bottom: 10px solid transparent;
    border-radius: 0;
    margin: 0;
}

.accordionTwo .panel-default {
    border: 0;
}

.accordionTwo .panel-default>.panel-heading {
    background: #f6f6f6;
    /* background: #4385f5; */
    border-radius: 0px;
    border-color: #f6f6f6;
    /* border-color: #4385f5; */
}

.accordion-wrap .panel-heading {
    padding: 0px;
    border-radius: 0px;
}

.panel-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    color: inherit;
}
.panel-title a{
    background-color: #DADADA!important;
}

.accordionTwo .panel .panel-heading a.collapsed {
    color: #999999;
    background-color: #fff;
    display: block;
    padding: 12px 20px;
}

.accordionTwo .panel .panel-heading a {
    display: block;
    padding: 12px 20px;
    /* color: #fff; */
}

.accordion-wrap .panel .panel-heading a {
    font-size: 14px;
}

.accordionTwo .panel-group .panel-heading+.panel-collapse>.panel-body {
    border-top: 0;
    padding-top: 0;
    padding: 20px 20px 20px 30px;
    background: #f6f6f6;
    /* background: #4385f5; */
    /* color: #fff; */
    font-size: 14px;
    line-height: 24px;
}

.accordionTwo .panel .panel-heading a:after {
    content: "\2212";
    /* color: #4285f4; */
    background: #fff;
}

.accordionTwo .panel .panel-heading a:after, .accordionTwo .panel .panel-heading a.collapsed:after {
    font-family: 'FontAwesome';
    font-size: 14px;
    float: right;
    width: 21px;
    display: block;
    height: 21px;
    line-height: 21px;
    text-align: center;
    border-radius: 50%;
    /* color: #FFF; */
}

.accordionTwo .panel .panel-heading a.collapsed:after {
    content: "\2b";
    /* color: #fff; */
    /* background-color: #DADADA; */
    background-color: #b9b9b9;
}

.accordionTwo .panel .panel-heading a:after {
    content: "\2212";
    /* color: #4285f4; */
    background: #dadada;
}

a:link {
    text-decoration: none
}
</style>
<script setup>
import { ref } from 'vue'
import BasicRequest from '@/utils/basic-request'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
/* vars */
const formularios = ref({});

/* functions */
const getFormularios = async () =>{
    await BasicRequest.getData('get-formularios')
    .then( e => formularios.value = e.formularios )
}
getFormularios()
</script>