<template>
    <form @submit.prevent="changePassword" class="mb-4">
        <template-container title="Cambiar mi contraseña">
            <template v-slot:contenido>
                <div class="d-flex align-item-end flex-row flex-wrap">
                    <inputs-validation tipo="password" class="col-12 col-sm-4" placeholder= "Contraseña actual"
                        label="Contraseña actual"
                        v-model="usuario.oldPassword" 
                        :isInvalid="$v.usuario.oldPassword.$invalid && send ? true : false"
                        :errorMsg="$v.usuario.oldPassword.minLengthValue.$invalid ? 'Debe tener al menos 8 caracteres' : 'Campo requerido'">
                    </inputs-validation>
                    <inputs-validation tipo="password" class="col-12 col-sm-5 my-3 my-sm-0" 
                        placeholder="Nueva contraseña"
                        label="Nueva contraseña"
                        v-model="usuario.newPassword" 
                        :isInvalid="$v.usuario.newPassword.$invalid && send ? true : false"
                        :errorMsg="msjValidacionPassActual()">
                    </inputs-validation>
                    <inputs-validation tipo="password" class="col-12 col-sm-3" 
                        placeholder="Repetir contraseña"
                        label="Repetir contraseña"
                        v-model="usuario.repeatPassword" 
                        :isInvalid="$v.usuario.repeatPassword.$invalid && send ? true : false"
                        :errorMsg="$v.usuario.repeatPassword.repeatPassword.$invalid ? 'Debe coincidir con la nueva password.' : 'Campo requerido'">
                    </inputs-validation>
                </div>
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-end">
                    <btn-loading type="submit" class="btn-primary" icon="fa fa-plus" :sending="loading" :disabled="loading" @click="changePassword"></btn-loading>
                </div>
            </template>
        </template-container>
    </form>
</template>
<script setup>
import TemplateContainer from '@/components/admin/template/TemplateContainer'
import InputsValidation from '@/components/admin/template/InputsValidation'
import BtnLoading from '@/components/admin/template/BtnLoading'
import BasicRequest from '@/utils/basic-request'
import Alert from '@/utils/alerts'
import {$v_password as validations} from '@/components/admin/usuario/validations'
import { useRouter } from 'vue-router'
// import { UseAuthStore } from '@/store/authStore'
import { ref } from 'vue'

/* vars */
const usuario = ref({});
const send = ref(false);
const loading = ref(false);
const router = useRouter();
// const useAuthStore = UseAuthStore();

/*validations*/
const $v = validations({usuario});

/* functions */
const changePassword = ()=>{
    send.value = true
    if($v.value.usuario.$invalid)
        return false
    loading.value = true;
    Alert.Option({ 
        icon: 'warning', 
        confirmButtonColor: "#198754",
        confirmButtonText: "Continuar",
        html: '<span>Esta a punto de modificar su contraseña.</span></br><span>¿Desea continuar?</span>'
        })
    .then((response)=>{
        if(response.isConfirmed){
            BasicRequest.postData('cambiar-password', usuario.value)
            .then(()=>{
                Alert.Success({text: 'Su contraseña se a cambiado con éxito.'}).then(()=> router.push( {name: 'VerPerfil'} ) )
            })
            .catch((error)=> {
                Alert.Error({html: 
                `<span>Error al crear usuario</span></br>
                <p>- ${error.response.data.error} -</p>`}) 
            })
            .finally(()=> loading.value = false )       
        }
    })
}

const msjValidacionPassActual = () =>{
    if($v.value.usuario.newPassword.notRepeatPassword.$invalid)
        return 'Su contraseña nueva no debe ser igual a la actual.'
    if($v.value.usuario.newPassword.minLengthValue.$invalid )
        return 'Su contraseña debe tener al menos 8 caracteres.'
    if($v.value.usuario.newPassword.$invalid )
        return 'Campo requerido.'
}
</script>
