<template>
    <form @submit.prevent="createUser" class="mb-4">
        <template-container title="Datos Personales">
            <template v-slot:contenido>
                <div class="d-flex align-item-end flex-row flex-wrap">
                    <inputs-validation class="col-12 col-sm-4" label="Apellido" v-model="usuario.apellido" :isInvalid="$v.usuario.apellido.$invalid && send ? true : false"></inputs-validation>
                    <inputs-validation class="col-12 col-sm-5" label="Nombre" v-model="usuario.nombre" :isInvalid="$v.usuario.nombre.$invalid && send ? true : false"></inputs-validation>
                    <inputs-validation class="col-12 col-sm-3" label="CUIL" v-model="usuario.cuil" :isInvalid="$v.usuario.cuil.$invalid && send ? true : false" :errorMsg="$v.usuario.cuil.numeric.$invalid ? 'El campo cuil permite solo números.' : 'Campo Requerido.'"></inputs-validation>
                </div>
            </template>
        </template-container>
        <template-container title="Datos contacto">
            <template v-slot:contenido>
                <div class="d-flex align-item-end flex-row flex-wrap">
                    <inputs-validation class="col-12 col-sm-6" label="Email" v-model="usuario.email" :isInvalid="$v.usuario.email.$invalid && send ? true : false" errorMsg="Ingrese un email válido."></inputs-validation>
                    <inputs-validation class="col-12 col-sm-6" label="Telefono" tipo="number" v-model="usuario.telefono" :isInvalid="$v.usuario.telefono.$invalid && send ? true : false"></inputs-validation>
                </div>
            </template>
        </template-container>
        <template-container>
            <template v-slot:contenido>
                <div class="d-flex justify-content-end">
                    <btn-loading type="submit" class="btn-primary" icon="fa fa-plus" :sending="loading" :disabled="loading" @click="createUser"></btn-loading>
                </div>
            </template>
        </template-container>
    </form>
</template>
<script setup>
import TemplateContainer from '@/components/admin/template/TemplateContainer'
import InputsValidation from '@/components/admin/template/InputsValidation'
import BtnLoading from '@/components/admin/template/BtnLoading'
import BasicRequest from '@/utils/basic-request'
import Alert from '@/utils/alerts'
import {$v as validations} from '@/components/admin/usuario/validations'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
/* vars */
const usuario = ref({});
const send = ref(false);
const loading = ref(false);
const router = useRouter();

/*validations*/
const $v = validations({usuario});

/* functions */
const createUser = ()=>{
    send.value = true
    if($v.value.usuario.$invalid)
        return false
    loading.value = true;
    BasicRequest.postData('users', usuario.value)
    .then(()=>{
        Alert.Success({text: 'Usuario creado con éxito'}).then(()=> router.push( {name: 'ListarUsuarios'} ) )
    })
    .catch(()=> Alert.Error({text: 'Error al crear usuario'}) )
    .finally(()=> loading.value = false )
}
</script>