<template>
    <div>
        <template-container title="Nuevo Formulario" class="mb-5">
            <template v-slot:contenido>
                <div>
                    <inputs-validation class="col-12 col-sm-12" label="Titulo" v-model="formulario.titulo" :isInvalid="$v.formulario.titulo.$invalid && send ? true : false"></inputs-validation>
                    <inputs-validation class="col-12 col-sm-12" label="Descripción" v-model="formulario.descripcion"></inputs-validation>
                    <input-text-area-validation class="col-12 col-sm-12 mb-3" label="Texto" v-model="formulario.texto"></input-text-area-validation>
                    <input-select-validation class="col-12 col-sm-12" label="Estado" v-model="formulario.estado" :datas="[{id:'Desbloqueado', descripcion: 'Desbloqueado'}, {id:'Bloqueado', descripcion: 'Bloqueado'}]"></input-select-validation>
                </div>
            </template>
        </template-container>
        <template-container title="Links de formularios">
            <template v-slot:contenido>
                <div class="d-flex align-item-end flex-column flex-wrap">
                    <div class="col-sm-6 col-12" v-if="!formulario.links">
                        <label for="" class="text-muted fw-bolder mb-2">Agregar links</label>
                        <div class="input-group mb-3">
                            <span class="input-group-text" id="basic-addon1"><i class="fa fa-link"></i></span>
                            <input v-model="link" type="text" class="form-control" :class="$v.link.$invalid && addLinkControll ? 'is-invalid' :''" placeholder="Ingrese un link..." aria-label="Ingrese un link..." aria-describedby="basic-addon1">
                            <button class="btn btn-primary" @click="agregarFormulario" title="Agregar link"><i class="fa fa-plus"></i></button>
                            <div class="invalid-feedback" v-if="$v.link.$invalid && addLinkControll">
                                <i class="fa fa-warning pe-2"></i>Por favor ingrese un link
                            </div>
                        </div>
                    </div>
                    <div class="mt-3" v-else>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Link</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><a :href="formulario.links.ruta" target="_blank"> {{formulario.links.ruta}} </a></td>
                                    <td><btn-loading :ref="crearDeletingsVars(i)" class="btn-default" icon="fa fa-times text-danger" texto="" :sending="deleting[i]" :disabled="deleting[i]" @click="deleteLink(formulario.links, i)"></btn-loading></td>
                                </tr>
                                <!-- <tr v-for="(link, i) in formulario.links" :key="i">
                                    <td><a :href="link.ruta" target="_blank"> {{link.ruta}} </a></td>
                                    <td><btn-loading :ref="crearDeletingsVars(i)" class="btn-default" icon="fa fa-times text-danger" texto="" :sending="deleting[i]" :disabled="deleting[i]" @click="deleteLink(link, i)"></btn-loading></td>
                                </tr> -->
                            </tbody>
                        </table>
                        <!-- <div>
                            <p class="text-center text-muted" v-if="!formulario.links">No a agregado links al formulario</p>
                        </div> -->
                    </div>
                </div>
            </template>
        </template-container>
        <template-container>
            <template v-slot:contenido>
                <div class="d-flex justify-content-end">
                    <btn-loading class="btn-danger me-3" texto="Eliminar" icon="fa fa-trash" :sending="loadingEliminar" :disabled="loadingEliminar" @click="eliminarFormulario"></btn-loading>
                    <btn-loading class="btn-primary" icon="fa fa-plus" :sending="loading" :disabled="loading" @click="editFormulario"></btn-loading>
                </div>
            </template>
        </template-container>
    </div>
</template>
<script setup>
import TemplateContainer from '@/components/admin/template/TemplateContainer'
import InputsValidation from '@/components/admin/template/InputsValidation'
import InputTextAreaValidation from '@/components/admin/template/InputTextAreaValidation'
import InputSelectValidation from '@/components/admin/template/InputSelectValidation'
import BtnLoading from '@/components/admin/template/BtnLoading'
import Alert from '@/utils/alerts'
import BasicRequest from '@/utils/basic-request'
import {$v as validations} from '@/components/admin/formulario/validations'
import { useRouter, useRoute } from 'vue-router'
import { ref } from 'vue'

/* vars */ 
const formulario = ref({
    links:{}
});
const link = ref(null);
const loading = ref(false);
const deleting = ref([]);
const loadingEliminar = ref(false);
const send = ref(false);
const addLinkControll = ref(false);
const router = useRouter();
const route = useRoute();

/*validations*/
const $v = validations({formulario, link});

/* function */
const getFormulario = ()=>{
    BasicRequest.getData('formularios/'+route.params.id)
    .then( e => formulario.value = e.formulario )
    .catch(()=> {
        Alert.Error({text: 'Error al obtener formulario'}).then(()=> router.push({name:'ListarFormularios'}) )
    })
    .finally(()=> loading.value = false )
}
getFormulario();

const editFormulario = ()=>{
    send.value = true
    if($v.value.formulario.$invalid){
        if($v.value.formulario.archivos.$invalid)
            Alert.Error({text: 'Debe ingresar algun archivo'})
        return false
    }
    loading.value = true 
    BasicRequest.putData('formularios/'+formulario.value.id, formulario.value)
    .then(()=>{
        Alert.Success({text: 'Formulario modificado con éxito'}).then(()=> router.push( {name: 'ListarFormularios'} ) )
    })
    .catch(()=> Alert.Error({text: 'Error al modificar formulario'}) )
    .finally(()=> loading.value = false )
}

const eliminarFormulario = ()=>{
    Alert.Option({ icon: 'warning', html: '<span>Estas a punto de eliminar este formulario y todos sus archivos.</span></br><span>¿Deseas continuar?</span>'})
    .then((response)=>{
        if(response.isConfirmed){
            loadingEliminar.value = true;
            BasicRequest.deleteData('formularios/'+formulario.value.id)
            .then(()=>{
                Alert.Success({text: 'Formulario eliminado con éxito'}).then(()=> router.push( {name: 'ListarFormularios'} ) )
            })
            .catch(()=> Alert.Error({text: 'Error al eliminar formulario'}) )
            .finally(()=> loadingEliminar.value = false )
        }
    })
}

const agregarFormulario = () =>{
    addLinkControll.value = true
    if($v.value.link.$invalid)
        return false;
    // formulario.value.links.push({ruta: link.value});
    formulario.value.links = {ruta: link.value};
    addLinkControll.value = false
    link.value = null;
}
const crearDeletingsVars = (index) => {
    deleting.value[index] = deleting.value[index] ? deleting.value[index] : false
}
const deleteLink = async (data, index)=>{
    deleting.value[index] = true
    if(!data.id){
        formulario.value.links = null
    }else{
        await BasicRequest.deleteData('formularioArchivos/'+data.id)
        .then(()=> formulario.value.links = null )
        // .then(()=> formulario.value.links = formulario.value.links.filter((elemento)=> elemento.id != data.id) )
        .catch(()=> Alert.Error({text: 'Error al eliminar link'}) )
    }
    deleting.value[index] = false
}
// const deleteLink = async (data, index)=>{
//     deleting.value[index] = true
//     if(!data.id){
//         formulario.value.links = formulario.value.links.filter((e, i)=> i != index )
//     }else{
//         await BasicRequest.deleteData('formularioArchivos/'+data.id)
//         .then(()=> formulario.value.links = formulario.value.links.filter((elemento)=> elemento.id != data.id) )
//         .catch(()=> Alert.Error({text: 'Error al eliminar link'}) )
//     }
//     deleting.value[index] = false
// }
</script>
