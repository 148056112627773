<template>
    <div>
        <div class="titlo-nota mt-3">
            <h4 class="text-muted">{{formulario.titulo}}</h4>
        </div>
        <div class="col-12 mt-5">
            <p class="col-auto" style="white-space: pre-wrap;">
                {{formulario.descripcion}}
            </p>
        </div>
        <div class="col-12 mt-5">
            <p class="col-auto" style="white-space: pre-wrap;">
                {{formulario.texto}}
            </p>
        </div>
        <div class="col-12 mt-5" v-for="archivo in formulario.archivos" :key="archivo.id">
            <p class="col-auto" style="white-space: pre-wrap;" v-if="formulario.estado == 'Desbloqueado'">
                <a :href="backend+'/'+archivo.ruta" class="text-decoration-none fw-bolder"><i class="fa-solid fa-download" style="font-size:30px"></i> Click para descargar {{formulario.titulo}}</a>
            </p>
        </div>
        <p class="col-auto text-danger" style="white-space: pre-wrap;" v-if="formulario.estado == 'Bloqueado'">
            Por el momento no se encuentra disponible este formulario para su descarga.
        </p>
    </div>
</template>
<script setup>
import { useRoute } from 'vue-router'
import { ref, watch } from 'vue'
import BasicRequest from '@/utils/basic-request'
import Alert from '@/utils/alerts'

/* vars */
const route = useRoute();
const formulario = ref({});
const backend = process.env.VUE_APP_URL_IMAGES;

/* functions */
const getFormulario = async () =>{
    await BasicRequest.getData('get-formularios/'+route.params.id)
    .then( e => formulario.value = e.formulario )
    .catch(()=> Alert.Error({text: 'No se pudo obtener el formulario'}) )
}
getFormulario();
// watchEffect(()=>{
//     getFormulario();
// })

watch(
  () => route.params.id,
  (newValue) => {
    if(newValue)
        getFormulario();
  },
  { deep: true }
)
</script>