<template>
     <template-container title="Listado de Días Festivos" class="mb-5" :loader="loader">
        <template v-slot:top>
            <div class="col-12 d-flex justify-content-end">
                <router-link :to="{ name: 'CreateDiaCalendario' }" class="btn btn-primary"><i class="fa-solid fa-plus"></i> Nuevo Día Festivo </router-link>
            </div>
        </template>
        <template v-slot:contenido>
            <div>                
                <input-search id="search" class="col-12 col-sm-6" v-model="search" :cargando="loading"></input-search>             
                <data-list :fields="fields" :datas="diasFestivos.data" @evento="router.push({ name: 'EditDiaCalendario', params: {id: $event.id} })"></data-list>
                <p class="text-center" v-if="diasFestivos.data && diasFestivos.data.length <= 0">No hay datos...</p>
                <div class="d-flex justify-content-center" v-if="diasFestivos.data && diasFestivos.data.length > 0">
                    <template-paginate :pages="diasFestivos" @changePage="getDiasFestivos($event)"></template-paginate>
                </div>
            </div>
        </template>
    </template-container>
</template>
<script setup>
import TemplateContainer from '@/components/admin/template/TemplateContainer'
import DataList from '@/components/admin/template/DataList'
import TemplatePaginate from '@/components/admin/template/TemplatePaginate'
import { useRouter } from 'vue-router'
import BasicRequest from '@/utils/basic-request'
import { ref, watchEffect, watch } from 'vue'
import Fecha from '@/utils/fecha'
import InputSearch from '@/components/admin/template/InputSearch'
import { Delay } from '@/utils/delay'
/* vars */
const router = useRouter();
const search = ref();
const diasFestivos = ref([]);
const loading = ref(false)
const loader = ref(true);
const fields = [
    {
        titulo: 'Descripción',
        key: 'descripcion',
        classTitulo: 'text-primary',
        classKey: 'fw-bold'
    },
    {
        titulo: 'Fecha',
        key: 'fecha',
        classTitulo: 'text-muted',
        classKey: 'text-muted fw-bold fw-sm-normal',
    }
]

/* functions */
const getDiasFestivos = (page, searchParam) =>{
    loader.value = true
    BasicRequest.getData('diasfestivos?page='+page, {params:{paginate:10, searchParam}})
    .then( e => diasFestivos.value = e.diasFestivos )
    .finally(()=> loader.value = false )
}
getDiasFestivos('0', '')

const busquedaConDelay = async ()=>{
    loading.value = true;
    await Delay.awaitFunction(2000)
    .then(() => {
        loading.value = false
        getDiasFestivos('0', search.value) 
    })
}

/* watchers */
watchEffect(()=>{
    if(diasFestivos.value.data)
        diasFestivos.value.data.filter((e)=> e.fecha = e.dia+" de "+Fecha.getMesString(e.mes))
})
watch(search, ()=> busquedaConDelay() )

</script>
