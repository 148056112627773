<template>
    <div class="mb-4">
        <template-container title="Editar categoría">
            <template v-slot:contenido>
                <div class="d-flex align-item-end flex-row flex-wrap">
                    <inputs-validation class="col-12 col-sm-12" label="Descripción" v-model="categoria.descripcion" :isInvalid="$v.categoria.descripcion.$invalid && send ? true : false"></inputs-validation>
                </div>
                <data-list-buttons class="my-3" title="Subcategorías asignadas:" label-select="Subcategorías" array-key="descripcion" :return-datas="categoria.subcategoria" :get-data="subcategorias"></data-list-buttons>
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-end">
                    <btn-loading class="btn-danger me-3" icon="fa fa-trash" texto="eliminar" :sending="loadingEliminar" :disabled="loadingEliminar" @click="eliminarCategoria"></btn-loading>
                    <btn-loading class="btn-primary" icon="fa fa-pencil" :sending="loading" :disabled="loading" @click="updateCategoria"></btn-loading>
                </div>
            </template>
        </template-container>
    </div>
</template>
<script setup>
import TemplateContainer from '@/components/admin/template/TemplateContainer'
import InputsValidation from '@/components/admin/template/InputsValidation'
import DataListButtons from '@/components/admin/template/DataListButtons'
import BtnLoading from '@/components/admin/template/BtnLoading'
import BasicRequest from '@/utils/basic-request'
import Alert from '@/utils/alerts'
import {$v as validations} from '@/components/admin/categoria/validations'
import { useRouter, useRoute } from 'vue-router'
import { ref } from 'vue'
/* vars */
const categoria = ref({});
const subcategorias = ref({});
const send = ref(false);
const loading = ref(false);
const loadingEliminar = ref(false);
const router = useRouter();
const route = useRoute();

/*validations*/
const $v = validations({categoria});

/* functions */
const getCategoria = async () =>{
    await BasicRequest.getData('categorias/'+route.params.id)
    .then( e => categoria.value = e.categoria )
    .catch(()=>{
        Alert.Error({text: 'No se pudo obtener la categoría'}).then(()=> router.push( {name: 'ListarCategorias'} ) )
    })
}
getCategoria();
const updateCategoria = ()=>{
    // send.value = true
    // if($v.value.categoria.$invalid)
    //     return false
    // loading.value = true;
    // BasicRequest.putData('categorias/'+categoria.value.id, categoria.value)
    // .then(()=>{
    //     Alert.Success({text: 'Categoria modificado con éxito'}).then(()=> router.push( {name: 'ListarCategorias'} ) )
    // })
    // .catch(()=> Alert.Error({text: 'Error al modificar la categoría'}) )
    // .finally(()=> loading.value = false )

    send.value = true
    if($v.value.categoria.$invalid)
        return false

    Alert.Option({ icon: 'info', html: '<span>Si eliminó alguna subcategoria que este asignada a una noticia, tambien se quitara.</span></br><span>¿Deseas continuar?</span>'})
    .then(async (response)=>{
        if(response.isConfirmed){
            try{
                loading.value = true;
                await BasicRequest.putData('categorias/'+categoria.value.id, categoria.value)
                Alert.Success({text: 'Categoria modificado con éxito'}).then(()=> router.push( {name: 'ListarCategorias'} ) )
            }catch(error){
                Alert.Error({text: 'Error al modificar la categoría'})
            }  
            loading.value = false
        }
    })
}
const eliminarCategoria = ()=>{
    Alert.Option({ icon: 'warning', html: '<span>Estas a punto de eliminar esta categoría.</span></br><span>¿Deseas continuar?</span>'})
    .then((response)=>{
        if(response.isConfirmed){
            loadingEliminar.value = true;
            BasicRequest.deleteData('categorias/'+categoria.value.id)
            .then(()=>{
                Alert.Success({text: 'Categoría eliminada con éxito'}).then(()=> router.push( {name: 'ListarCategorias'} ) )
            })
            .catch(()=> Alert.Error({text: 'Error al eliminar la categoría'}) )
            .finally(()=> loadingEliminar.value = false )
        }
    })
}

const getSubCategorias = async () =>{
    await BasicRequest.getData('subcategorias')
    .then((e)=>{
        subcategorias.value = e.subcategorias
    })
}
getSubCategorias()
</script>