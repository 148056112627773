<template>
    <div class="mb-4">
        <template-container title="Crear categoría">
            <template v-slot:contenido>
                <div class="d-flex align-item-end flex-row flex-wrap">
                    <inputs-validation class="col-12 col-sm-12" label="Descripción" v-model="categoria.descripcion" :isInvalid="$v.categoria.descripcion.$invalid && send ? true : false"></inputs-validation>
                </div>
                <data-list-buttons class="my-3" title="Subcategorías asignadas:" label-select="Subcategorías" array-key="descripcion" :return-datas="categoria.subcategoria" :get-data="subcategorias"></data-list-buttons>
            </template>
            <template v-slot:footer>
                <div class="d-flex justify-content-end">
                    <btn-loading class="btn-primary" icon="fa fa-pencil" :sending="loading" :disabled="loading" @click="createCategoria"></btn-loading>
                </div>
            </template>
        </template-container>
    </div>
</template>
<script setup>
import TemplateContainer from '@/components/admin/template/TemplateContainer'
import InputsValidation from '@/components/admin/template/InputsValidation'
import DataListButtons from '@/components/admin/template/DataListButtons'
import BtnLoading from '@/components/admin/template/BtnLoading'
import BasicRequest from '@/utils/basic-request'
import Alert from '@/utils/alerts'
import {$v as validations} from '@/components/admin/categoria/validations'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
/* vars */
const categoria = ref({
    subcategoria: []
});
const subcategorias = ref({});
const send = ref(false);
const loading = ref(false);
const router = useRouter();

/*validations*/
const $v = validations({categoria});

/* functions */
const createCategoria = ()=>{
    send.value = true
    if($v.value.categoria.$invalid)
        return false
    loading.value = true;
    BasicRequest.postData('categorias', categoria.value)
    .then(()=>{
        Alert.Success({text: 'Categoría creada con éxito'}).then(()=> router.push( {name: 'ListarCategorias'} ) )
    })
    .catch(()=> Alert.Error({text: 'Error al crear la categoría'}) )
    .finally(()=> loading.value = false )
}
const getSubCategorias = async () =>{
    await BasicRequest.getData('subcategorias')
    .then((e)=>{
        subcategorias.value = e.subcategorias
    })
}
getSubCategorias()
</script>