<template>
    <div class="nota col-sm-10 col-12 mb-5">
        <div class="col-auto">
            <span class="px-5 py-2 background-violeta text-white position-relative d-inline-flex flex-wrap" style="min-height: 36px;">
                <span v-for="(notaCategoria, i) in nota.categorias" :key="i">
                    {{notaCategoria.descripcion}}<span v-if="i < nota.categorias.length - 1" class="mx-1">-</span>
                </span> 
            </span> 
        </div>
        <div class="col-auto border-bottom-violeta">
            <span class="px-4 py-0 my-2 text-white fw-bolder subcategorias position-relative d-inline-flex flex-wrap" style="min-height: 25px;">
                <template v-for="(notaCategoria, index) in nota.categorias" :key="index">
                    <span v-for="subcategoria in notaCategoria.subcategorias" :key="subcategoria.id" class="color-mediumPurple">
                        {{subcategoria.descripcion}}<span v-if="index < notaCategoria.subcategorias.length - 1" class="mx-1">-</span>
                    </span>
                </template> 
            </span> 
        </div>
         <div class="titlo-nota mt-3">
            <h4 class="text-muted">{{nota.titulo}}</h4>
        </div>
        <div class="col-12 imagen-nota">
            <carousel-bootstrap :images="nota.imagenes"></carousel-bootstrap>
        </div>
        <div class="d-flex justify-content-end mt-2 flex-column-reverse">
            <div class="col mt-1">
                <p class="col-auto" style="white-space: pre-wrap;">
                    {{nota.pie_foto}}
                </p>
            </div>
            <!-- <div class="d-flex justify-content-end mt-1 mb-3">
                <div class="d-flex flex-column justify-content-center">
                    <div class="d-flex justify-content-center">
                        <small class="text-muted pe-2">Hace</small>
                        <small class="fw-bolder text-muted text-center"><i class="fa fa-clock"></i> {{moment.duration((moment.utc().unix() - moment.utc(nota.created_at).unix()), "seconds").format("y[ año] d[ días] h[h] m[m]")}}</small>
                    </div>
                </div>
                <div class="cuadrado background-violeta ms-2">
                    <span class="boton-violeta"></span>
                </div>
            </div> -->
        </div>
        <div class="col-12 mt-5">
            <div v-html="nota.texto" class="col-auto ql-editor p-0" style="white-space: pre-wrap;">
                
            </div>
        </div>
    </div>
</template>
<style scoped src="@/assets/publico/notaprincipal.css"></style>
<script setup>
import CarouselBootstrap from '@/components/public/template/CarouselBootstrap'
import { useRoute } from 'vue-router'
import { ref } from 'vue'
import BasicRequest from '@/utils/basic-request'
import Alert from '@/utils/alerts'
import '@vueup/vue-quill/dist/vue-quill.core.css';
import { useHead } from '@vueuse/head'


/* vars */
const route = useRoute();
const nota = ref({});
const backend = process.env.VUE_APP_URL_IMAGES;

/* functions */
const getNota = async () =>{
    await BasicRequest.getData('get-notas/'+route.params.id)
    .then( e => nota.value = e.nota )
    .catch(()=> Alert.Error({text: 'No se pudieron obtener las noticias'}) )
    
    let palabrasClaves = "Instituto María Auxiliadora, educación, formación integral, excelencia académica, educación inicial, educación secundaria, educación privada, educación de calidad, eventos educativos, noticias educativas, María, Auxiliadora, colegio, secundario, primario, santa rosa, la, pampa, argentina, ";
    
    let metaTagImagenRuta = require('@/assets/no-image-1.png');
    if(nota.value.imagenes.length > 0 )
        metaTagImagenRuta = backend +'/'+nota.value.imagenes[0].ruta

    useHead({
        title: nota.value.titulo +' | María Auxiliadora',
        meta: [
            {
            name: `description`,
            content: nota.value.pie_foto,
            },
            {
            name: `keywords`,
            content: palabrasClaves + nota.value.titulo.replaceAll(' ', ', '),
            },
            {
            name: `date`,
            content: nota.value.created_at,
            },
            {
            name: `og:title`,
            content: nota.value.titulo,
            },
            {
            name: `og:image`,
            content: metaTagImagenRuta,
            },
            {
            name: `og:description`,
            content: nota.value.pie_foto,
            },
        ],
            
    })
}
getNota();
</script>

