<template>
    <div>
        <h2 class="fw-bolder mb-4 text-violeta ms-3 ms-sm-0">¿Quienes somos?</h2> 
        <section class="mx-sm-3 my-2">
            <div class="card border-0">
                <div class="card-body">                    
                    <h5><i class="fa fa-check text-success me-2"></i> Visión:</h5> 
                    <p class="text-muted ps-3 ms-3">
                        Ser una Escuela-Casa-Comunidad que educa y acompaña integralmente a niños y jóvenes comprometiendo a todos los integrantes de la Comunidad Educativa Pastoral.
                    </p>
                </div>
            </div>
        </section>
        <section class="mx-sm-3 my-2">
            <div class="card border-0">
                <div class="card-body">                    
                    <h5><i class="fa fa-check text-success me-2"></i> Misión:</h5> 
                    <p class="text-muted ps-3 ms-3">
                        Ser una escuela que se educa y educa, en un clima de familia y respondiendo al Sistema Preventivo de Don Bosco, para la construcción de personas críticas y responsables, “buenos cristianos y honrados ciudadanos”.
                    </p>
                </div>
            </div>
        </section>
        <section class="mx-sm-3 my-2">
            <div class="card border-0">
                <div class="card-body">                    
                    <h5><i class="fa fa-check text-success me-2"></i> Valores:</h5> 
                    <p class="text-muted ps-3 ms-3">
                        Nuestra propuesta educativa se basa en el acompañamiento como estilo carismático de encuentro y vínculo con todos los miembros de la Comunidad Educativa Pastoral, el respeto por los procesos personales y la adhesión al carisma inspirado por el Espíritu Santo a Don Bosco y Madre Mazzarello.
                    </p>
                </div>
            </div>
        </section>
    </div>
</template>
<script setup>


</script>
