import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'

export const $v = data => useVuelidate(rules, data)

export const rules = {
    nota:{
        titulo: { required },
        pie_foto: { required },
        texto: { required }
    }
}