<template>
    <table class="table tabla-responsiva">
        <thead>
            <tr>
                <th v-for="(field, i) in fields" :key="i">{{field.titulo}}</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(data, i) in datas" :key="i" class="hovered" role="button">                
                <td v-for="(field, b) in fields" :key="b" @click="emit('evento', data)">
                    <span class="titulo me-1" :class="field.classTitulo"><small>{{field.titulo}}</small></span>
                    <template v-if="typeof getKeys(data, field.key) == 'object'">
                        <template v-for="(datos2, index) in getKeys(data, field.key)" :key="index">
                            <template v-if="typeof getKeys(datos2, field.key) == 'object'">
                                <template v-for="(datos3, index) in getKeys(datos2, field.key)" :key="index">
                                    <span class="key pe-2" :class="field.classPerKey ? field.classPerKey[i] : field.classKey">{{getKeys(datos3, field.key)}}</span>
                                </template>
                            </template>
                            <template v-else>
                                <span class="key" :class="field.classPerKey ? field.classPerKey[i] : field.classKey">{{getKeys(datos2, field.key)}}</span>
                            </template>
                        </template>
                    </template>
                    <template v-else>
                        <span class="key" :class="field.classPerKey ? field.classPerKey[i] : field.classKey">{{getKeys(data, field.key)}}</span>
                    </template>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script setup>
import { defineProps, defineEmits } from 'vue'

defineProps({
    fields: null,
    datas: {}
})
const emit = defineEmits(['evento']);

const getKeys = (data, key)=>{
    //busca la key de un array en el caso que sea un objeto
    let keys = key.split('.')
    let retorno = data;
    keys.forEach((e) => {
        for(var propiedad in retorno){
            if(e == propiedad){
                retorno = retorno[propiedad];
                return true;
            }
        }
    })
    return retorno
}
</script>
<style>
.tabla-responsiva tbody tr span.titulo{
    display: none;
}
.hovered:hover{
    background-color: #e9e9e9;
}
@media (max-width: 599px) {
    .tabla-responsiva tbody tr td:first-child span.titulo{
        display: none;
    }
    .tabla-responsiva{
        width: 100%;
        overflow: hidden;
    }
    .tabla-responsiva thead{
        display: none;
    }
    .tabla-responsiva tbody tr{
        display: grid;
        grid-template-columns: 100% repeat(auto, 200px);
        border-bottom: 1px solid rgb(207, 207, 207);
    }
    .tabla-responsiva tbody tr td:first-child{
        border: none;
        display: flex;
        grid-template-columns: repeat(1, 100%);
    }
    .tabla-responsiva tbody tr td{
        border: none;
        display: flex;
        flex-wrap: wrap;
    }
    .tabla-responsiva tbody tr span.titulo{
        display: block;
    }
    .tabla-responsiva tbody tr span.key{
        word-break: break-word;
    }
}
</style>