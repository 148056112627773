<template>
     <template-container title="Modificar Día Festivo" class="mb-5">
        <template v-slot:contenido>
            <div class="d-flex align-item-end flex-row flex-wrap">
                <inputs-validation class="col-12 col-sm-6" label="Descripción" v-model="diaCalendario.descripcion" :isInvalid="$v.diaCalendario.descripcion.$invalid && send ? true : false"></inputs-validation>
                <input-select-validation label="Día" v-model="diaCalendario.dia" :datas="dias()" :isInvalid="$v.diaCalendario.dia.$invalid && send ? true : false"></input-select-validation>
                <input-select-validation label="Mes" v-model="diaCalendario.mes" :datas="meses" :isInvalid="$v.diaCalendario.mes.$invalid && send ? true : false"></input-select-validation>
                <input-select-validation label="Tipo de día" v-model="diaCalendario.tipo" :datas="data" :isInvalid="$v.diaCalendario.tipo.$invalid && send ? true : false"></input-select-validation>
            </div>
        </template>
        <template v-slot:footer>
            <div class="d-flex justify-content-end">
                <btn-loading class="btn-danger me-3" icon="fa fa-trash" texto="Eliminar" :sending="loadingEliminar" :disabled="loadingEliminar" @click="eliminarDiafestivo"></btn-loading>
                <btn-loading class="btn-primary" icon="fa fa-plus" :sending="loading" :disabled="loading" @click="guardar"></btn-loading>
            </div>
        </template>
    </template-container>
</template>
<script setup>
import TemplateContainer from '@/components/admin/template/TemplateContainer'
import InputsValidation from '@/components/admin/template/InputsValidation'
import InputSelectValidation from '@/components/admin/template/InputSelectValidation'
import {$v as validations} from '@/components/admin/diascalendario/validations'
import BtnLoading from '@/components/admin/template/BtnLoading'
import BasicRequest from '@/utils/basic-request'
import Alert from '@/utils/alerts'
import { meses } from '@/utils/meses'
import { useRouter, useRoute } from 'vue-router'
import { ref } from 'vue'
/* vars */
const diaCalendario = ref({});
const router = useRouter();
const route = useRoute();
const send = ref(false);
const loading = ref(false);
const loadingEliminar = ref(false);
const data = [
    {
        descripcion: "Día oficial"
    },
    {
        descripcion: "Día no oficial"
    },
    {
        descripcion: "Día popular"
    }
]
const dias = ()=>{
    let dias = []
    for(let i = 0; i < 31; i++){
        dias[i] = {
            id: i + 1,
            descripcion: i + 1,
        }
    }
    return dias
}

/*validations*/
const $v = validations({diaCalendario});

/* functions */
const getDiaFestivo = async ()=>{
    await BasicRequest.getData('diasfestivos/'+route.params.id)
    .then((e)=>{
        diaCalendario.value = e.diafestivo
    })
    .catch(()=> {
        Alert.Error({text: 'No se pudo obtener día festivo'})
        .then(()=>{
            router.push( {name: 'ListarDiasCalendario'} )
        })
    })
    .finally(()=> loading.value = false )
}
getDiaFestivo();
const guardar = ()=>{
    send.value = true
    if($v.value.diaCalendario.$invalid)
        return false
    loading.value = true;
    BasicRequest.putData('diasfestivos/'+route.params.id, diaCalendario.value)
    .then(()=>{
        Alert.Success({text: 'Día festivo modificado con éxito'}).then(()=> router.push( {name: 'ListarDiasCalendario'} ) )
    })
    .catch(()=> Alert.Error({text: 'Error al modificar día festivo'}) )
    .finally(()=> loading.value = false )
}
const eliminarDiafestivo = ()=>{
    Alert.Option({ icon: 'warning', html: '<span>Estas a punto de eliminar esta día festivo.</span></br><span>¿Deseas continuar?</span>'})
    .then((response)=>{
        if(response.isConfirmed){
            loadingEliminar.value = true;
            BasicRequest.deleteData('diasfestivos/'+diaCalendario.value.id)
            .then(()=>{
                Alert.Success({text: 'Día festivo eliminado con éxito'}).then(()=> router.push( {name: 'ListarDiasCalendario'} ) )
            })
            .catch(()=> Alert.Error({text: 'Error al eliminar día festivo'}) )
            .finally(()=> loadingEliminar.value = false )
        }
    })
}

</script>
