<template>
    <div class="mb-4">
        <template-container title="Datos Personales">
            <template v-slot:contenido>
                <div class="d-flex align-item-end flex-row flex-wrap">
                    <inputs-validation class="col-12 col-sm-4" label="Apellido" v-model="usuario.apellido" :isInvalid="$v.usuario.apellido.$invalid && send ? true : false"></inputs-validation>
                    <inputs-validation class="col-12 col-sm-5" label="Nombre" v-model="usuario.nombre" :isInvalid="$v.usuario.nombre.$invalid && send ? true : false"></inputs-validation>
                    <inputs-validation class="col-12 col-sm-3" label="CUIL" v-model="usuario.cuil" :isInvalid="$v.usuario.cuil.$invalid && send ? true : false" :errorMsg="$v.usuario.cuil.numeric.$invalid ? 'El campo cuil permite solo números.' : 'Campo Requerido.'"></inputs-validation>
                </div>
            </template>
        </template-container>
        <template-container title="Datos contacto">
            <template v-slot:contenido>
                <div class="d-flex align-item-end flex-row flex-wrap">
                    <inputs-validation class="col-12 col-sm-6" label="Email" v-model="usuario.email" :isInvalid="$v.usuario.email.$invalid && send ? true : false" errorMsg="Ingrese un email válido."></inputs-validation>
                    <inputs-validation class="col-12 col-sm-6" label="Telefono" tipo="number" v-model="usuario.telefono" :isInvalid="$v.usuario.telefono.$invalid && send ? true : false"></inputs-validation>
                </div>
            </template>
        </template-container>
        <template-container>
            <template v-slot:contenido>
                <div class="d-flex justify-content-end flex-column-reverse gap-2 flex-sm-row">
                    <router-link class="btn btn-danger" :to="{name: 'CambiarPassword'}"><i class="fa fa-key me-1"></i> Cambiar contraseña</router-link>
                    <btn-loading class="btn-primary" icon="fa fa-plus" :sending="loading" :disabled="loading" @click="updateUser"></btn-loading>
                </div>
            </template>
        </template-container>
    </div>
</template>
<script setup>
import TemplateContainer from '@/components/admin/template/TemplateContainer'
import InputsValidation from '@/components/admin/template/InputsValidation'
import BtnLoading from '@/components/admin/template/BtnLoading'
import BasicRequest from '@/utils/basic-request'
import Alert from '@/utils/alerts'
import {$v as validations} from '@/components/admin/usuario/validations'
import { useRouter, useRoute } from 'vue-router'
import { UseAuthStore } from '@/store/authStore'
import { ref, watch } from 'vue'
/* vars */
const usuario = ref({});
const send = ref(false);
const loading = ref(false);
const router = useRouter();
const route = useRoute();
const useAuthStore = UseAuthStore();

/*validations*/
const $v = validations({usuario});

/* functions */
const getUser = async () =>{
    if(!route.params.id || !route.params)
        usuario.value = {...useAuthStore.usuario}
    else{
        await BasicRequest.getData('users/'+route.params.id)
        .then( e => usuario.value = e.user )
        .catch(()=>{
            Alert.Error({text: 'No se pudo obtener usuario'}).then(()=> router.push( {name: 'ListarUsuarios'} ) )
        })
    }
}
getUser();
const updateUser = ()=>{
    send.value = true
    if($v.value.usuario.$invalid)
        return false
    loading.value = true;
    BasicRequest.putData('users/'+usuario.value.id, usuario.value)
    .then(()=>{
        Alert.Success({text: 'Usuario modificado con éxito'}).then(()=> router.push( {name: 'ListarUsuarios'} ) )
    })
    .catch(()=> Alert.Error({text: 'Error al modificar usuario'}) )
    .finally(()=> loading.value = false )
}

watch(
  () => route.params.id,
  (newValue) => {
    if(newValue || newValue == undefined)
        getUser();
  },
  { deep: true }
)
</script>
