<template>
    <div>
        <template-header></template-header>
        <section class="px-3 container">
            <div class="col row mt-2">
                <div class="col diasFestivos me-3">              
                    <div class="col row" v-for="diaFestivo in diasFestivos" :key="diaFestivo.id">
                        <span class="col my-0 fw-bolder text-muted">{{diaFestivo.dia}} de {{Fecha.getMesString(diaFestivo.mes)}}</span>
                        <span class="col text-end my-0 descripcion fw-bolder text-muted">{{diaFestivo.descripcion}} - {{diaFestivo.tipo}}</span>
                    </div>              
                </div>
                <div class="col-sm-2 mt-2 mt-sm-0">
                    <input-search class="col-12" claseInput="rounded-0"></input-search>
                </div>
            </div>
        </section>
        <section class="px-3 container mt-3">
            <div class="d-flex justify-content-between mt-2">
                <div class="col-sm-10 col-12">
                        <div class="nota col-sm-10 col-12 mb-5" v-if="nota">
                            <div class="col-auto mb-3" v-if="Array.isArray(nota.categorias)">
                                <span class="px-5 py-2 background-violeta text-white position-relative d-inline-flex flex-wrap" style="min-height: 36px;">
                                    <span v-for="(notaCategoria, index) in nota.categorias" :key="index">
                                        {{notaCategoria.descripcion}}<span v-if="index < nota.categorias.length - 1" class="mx-1">-</span>
                                    </span> 
                                    <!-- <span class="flecha background-violeta"></span> -->
                                </span> 
                            </div>
                            <div class="col-auto mb-3" v-else>
                                <span class="px-5 py-2 background-violeta text-white position-relative d-inline-flex flex-wrap" style="min-height: 36px;">
                                    <span>
                                        {{nota.categorias?.descripcion}} 
                                    </span> 
                                    <span class="flecha background-violeta"></span>
                                </span> 
                            </div>
                            <div class="col-auto border-bottom-violeta">
                                <span class="px-4 py-0 my-2 text-white fw-bolder subcategorias position-relative d-inline-flex flex-wrap" style="min-height: 25px;">
                                    <template v-for="(notaCategoria, index) in nota.categorias" :key="index">
                                        <span v-for="subcategoria in notaCategoria.subcategorias" :key="subcategoria.id" class="color-mediumPurple">
                                            {{subcategoria.descripcion}}<span v-if="index < notaCategoria.subcategorias.length - 1" class="mx-1">-</span>
                                        </span>
                                    </template> 
                                </span> 
                            </div>
                            <div class="titlo-nota mt-3">
                                <h4 class="text-muted">{{nota.titulo}}</h4>
                            </div>
                            <div class="col-12 imagen-nota">
                                 <div id="carouselExampleIndicators" class="carousel slide h-100" data-bs-ride="carousel">
                                    <div class="carousel-indicators z-index-10">
                                        <template v-for="(image, index) in nota.imagenes" :key="index">
                                            <button type="button" data-bs-target="#carouselExampleIndicators" :data-bs-slide-to="index" :class="index==0 ? 'active' : ''" aria-current="true" :aria-label="'Slide '+index"></button>
                                        </template>
                                    </div>
                                    <div class="carousel-inner h-100">
                                        <div style="min-height: 300px;" class="carousel-item h-100" :class="index2==0 ? 'active' : ''" v-for="(image, index2) in nota.imagenes" :key="index2">
                                            <img :src="image.prev ?? backend+'/'+image.ruta" @error="imageError" alt="" class="img-scale-down">
                                            <img :src="image.prev ?? backend+'/'+image.ruta" @error="imageError" alt="" class="img-fondo">
                                        </div>
                                        <div class="carousel-item active h-100" v-if="!nota.imagenes || nota.imagenes.length == 0">
                                            <img src="@/assets/no-image-1.png" alt="" class="img-scale-down">
                                            <img src="@/assets/no-image-1.png" alt="" class="img-fondo">
                                        </div> 
                                    </div>
                                    <button class="carousel-control-prev z-index-10" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" v-show="nota.imagenes && nota.imagenes.length > 1">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Previous</span>
                                    </button>
                                    <button class="carousel-control-next z-index-10" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" v-show="nota.imagenes && nota.imagenes.length > 1">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="visually-hidden">Next</span>
                                    </button>
                                </div>
                            </div>
                            <!-- <div class="d-flex justify-content-end mt-2">
                                <div class="col mt-1">
                                    <p class="col-auto" style="white-space: pre-wrap;">
                                        {{nota.pie_foto}}
                                    </p>
                                </div>
                                <div class="d-flex flex-column">
                                    <span class="fw-bolder text-muted">Maul artes visuales</span>
                                    <span class="fw-bolder text-muted">Maul artes visuales</span>
                                </div>
                                <div class="cuadrado boton-violeta ms-3">
                                    <span class="boton-violeta"></span>
                                </div>
                            </div> -->
                            <div class="col-12 mt-5">
                                <div v-html="nota.texto" class="col-auto ql-editor p-0" style="white-space: pre-wrap;">
                                    
                                </div>
                            </div>
                        </div>
                </div>
                <div class="columna-izquierda col-sm-2 col-12 mt-lg-5 pt-lg-3 border-start border-3 d-none d-sm-block">
                    <template-menu class="overflow-hidden" nameComponent='ListarNotasPorCategoria'></template-menu>
                </div>
            </div>
        </section>
    </div>
</template>
<style scoped src="@/assets/publico/style.css"></style>
<style scoped src="@/assets/publico/notaprincipal.css"></style>
<script setup>
import TemplateHeader from '@/components/public/template/TemplateHeader'
import TemplateMenu from '@/components/public/template/TemplateMenu'
import InputSearch from '@/components/admin/template/InputSearch'
import BasicRequest from '@/utils/basic-request'
import { ref } from 'vue'
import moment from 'moment';
import Fecha from '@/utils/fecha'
import '@vueup/vue-quill/dist/vue-quill.core.css';

/* vars */
const diasFestivos = ref({});
const mes = ref(moment().locale('es').format("MM"))
const dia = ref(moment().locale('es').format("DD"))
const nota = JSON.parse(localStorage.getItem('nota'))
const backend = process.env.VUE_APP_URL_IMAGES;

/* functions */
const getDiaFestivo = async () =>{
    await BasicRequest.getData('get-diasfestivos', {params:{dia:dia.value, mes:mes.value}})
    .then( e => diasFestivos.value = e.diasFestivos )
}
getDiaFestivo()
const imageError = (event)=>{
    event.target.src = require('@/assets/no-image-1.png')
}

</script>

