import useVuelidate from '@vuelidate/core'
import { required, email, numeric, minLength } from '@vuelidate/validators'

export const $v = data => useVuelidate(rules, data)

export const rules = {
    usuario:{
        nombre: { required },
        apellido: { required },
        cuil: { required, numeric },
        email: { required, email },
        telefono: { required }
    }
}

export const $v_password = data => useVuelidate(rulesPassword, data)

const repeatPasswordControl = (password, usuario) => password === usuario.newPassword;
const notRepeatPasswordControl = (password, usuario) => password !== usuario.oldPassword;
export const rulesPassword = {
    usuario:{       
        oldPassword: { 
            required ,
            minLengthValue: minLength(8),
        },
        newPassword: { 
            required ,
            minLengthValue: minLength(8),
            notRepeatPassword: notRepeatPasswordControl
        },
        repeatPassword: { 
            required,
            repeatPassword: repeatPasswordControl
        },
    }
}