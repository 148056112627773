import Axios from 'axios'
import { ref, provide } from 'vue'
export default class BasicRequest {
    
    static getData(url, dataParams){
        const loader = ref(true)
        provide('loader', loader)
        return new Promise((resolve, reject)=>{
            if(dataParams){
                Axios.get(url, dataParams).then((e)=>{
                    loader.value = false
                    return resolve( e.data );
                }).catch((error)=>{
                    return reject(error)
                })
            }else{
                Axios.get(url).then((e)=>{
                    loader.value = false
                    return resolve( e.data );
                }).catch((error)=>{
                    return reject(error)
                })
            }
        })
    }
    static postData(url, data){
        return new Promise((resolve, reject)=>{
            Axios.post(url, data).then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
    static async putData(url, data){
        return new Promise((resolve, reject)=>{
            Axios.put(url, data )
            .then( e => resolve( e ))
            .catch(error => reject(error) )
        })
    }
    static deleteData(url){
        return new Promise((resolve, reject)=>{
            Axios.delete(url).then((e)=>{
                return resolve(e)
            }).catch((error)=>{
                return reject(error)
            })
        })
    }
}