<template>
    <div class="vh-100 col-12 d-flex align-items-center justify-content-center px-3">
        <pre-sending v-if="loading" texto="Aguarde. Procesando datos..."></pre-sending>
        <form @submit.prevent="enviar" class="col-sm-5 col-12">
            <h2 class="text-muted pb-2"> Ultimo paso de recuperación de contraseña</h2>
            <div class="mb-2">
                <label for="" class="text-muted fw-bold"><i class="fa-solid fa-code"></i> Código</label>
                <input type="text" v-model="user.codigo" class="form-control" placeholder="Ingrese el código" :class="v.codigo.$invalid && send ? 'is-invalid' : ''">
                <div class="invalid-feedback">
                    <i class="fa fa-warning pe-2"></i>Debe ingresar el código que figura en el correo que le emos enviado.
                </div>
            </div>
            <div class="mb-2">
                <label for="" class="text-muted fw-bold"><i class="fa-solid fa-key"></i> Nueva contraseña</label>
                <input type="password" v-model="user.password" class="form-control" placeholder="Ingrese su nueva contraseña" :class="v.password.$invalid && send ? 'is-invalid' : ''">
                <div class="invalid-feedback" v-if="v.password.required.$invalid">
                    <i class="fa fa-warning pe-2"></i>Debe ingresar una contraseña válida
                </div>
                <div class="invalid-feedback" v-if="v.password.minLengthValue.$invalid">
                    <i class="fa fa-warning pe-2"></i>La contraseña elegida debe tener minumo 8 caracteres
                </div>
            </div>
            <div class="mb-2">
                <label for="" class="text-muted fw-bold"><i class="fa-solid fa-key"></i> Repetir contraseña</label>
                <input type="password" v-model="user.password_confirmation" class="form-control" placeholder="Repita la nueva contraseña" :class="v.password_confirmation.$invalid && send ? 'is-invalid' : ''">
                <div class="invalid-feedback" v-if="v.password_confirmation.required.$invalid || v.password_confirmation.repeatPassword.$invalid">
                    <i class="fa fa-warning pe-2"></i>Por favor, repita la contraseña.
                </div>
            </div>
            <div class="col-12 justify-content-end d-flex flex-column-reverse flex-sm-row mt-2">
                <button type="sumbit" class="boton boton-violeta px-3"><i class="fa-solid fa-check me-2"></i> Enviar</button>
            </div>
        </form>
    </div>
</template>
<style scoped src="@/assets/publico/style.css"></style>

<script setup>
import { ref } from 'vue'
import Alert from '@/utils/alerts'
import BasicRequest from '@/utils/basic-request'
import { useRouter, useRoute } from 'vue-router'
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import PreSending from '@/components/public/template/PreSending'

/* vars */
const router = useRouter();
const route = useRoute();
const loading = ref(false);
const user = ref({
    token: route.query.token,
    email: route.query.e,
    password:null,
    password_confirmation: null
});
const send = ref(false);
const repeatPasswordControl = password => password === user.value.password;
const rules = {
    codigo: { required },
    password: { 
        required ,
        minLengthValue: minLength(8),
    },
    password_confirmation: { 
        required,
        repeatPassword: repeatPasswordControl
    },
}
const v = useVuelidate(rules, user);


/* functions */
const enviar = async ()=>{
    send.value = true
    if(v.value.$invalid){
        return false
    }
    // Alert.Loading({timer: 2000, title: 'Aguarde...'})
    loading.value = true
    await BasicRequest.postData('reset-password', {...user.value})
    .then(async () => {
        loading.value = false
        Alert.Close()
        Alert.Success({ titles:'!Éxito!',
        html: `
        <p class="text-muted">Su contraseña fue restablecida exitosamente.</p>
        <p class="text-muted">Ahora puede iniciar sesión con normalidad.</p>
        `}).then(()=>{
            router.push({ name: 'LoginView'})
        })
    })
    .catch((e)=> {
        loading.value = false
        Alert.Error({ titles:'Opps...', 
        html: `
        <p class="text-muted">No se pudo realizar el cambio de contraseña.</p>
        <small class="text-muted"> - ${e.response.data.message} -</small>
        `})
    }).finally(()=>{
        loading.value = false
    })
    
}
</script>
